import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ChangePasswordScreen from "../../components/editUser/ChangePasswordScreen";
import EditProfil from "../../components/editUser/EditProfil";
const EditProfilScreen = () => {
  const [optionIsSelected, setOptionIsSelected] = useState(false);

  return (
    <div style={{ alignItems: "center" }}>
      <main className="container" style={{ textAlign: "center" }}>
        <div className="form-check">
          <h1 className="text-center mt-3">Modifier Profile</h1>
        </div>
        <Row style={{ margin: "20px 0" }}>
          <Col
            onClick={() => {
              setOptionIsSelected(false);
            }}
            style={{
              backgroundColor: "#F1F1F1",
              textAlign: "center",
              padding: "10px",
              backgroundColor: !optionIsSelected ? "#d1f1ff" : "",
            }}
          >
            <h3>Profile</h3>
          </Col>
          <Col
            onClick={() => {
              setOptionIsSelected(true);
            }}
            style={{
              backgroundColor: "#F1F1F1",
              textAlign: "center",
              padding: "10px",
              backgroundColor:  optionIsSelected ? "#d1f1ff" : "",
            }}
          >
            <h3>Mot de passe</h3>
          </Col>
        </Row>

        {optionIsSelected ? <ChangePasswordScreen /> : <EditProfil />}
      </main>
    </div>
  );
};

export default EditProfilScreen;
