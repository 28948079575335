import {
  PUBLIC_CATEGORY_LIST_FAIL,
  PUBLIC_CATEGORY_LIST_REQUEST,
  PUBLIC_CATEGORY_LIST_SUCCESS,
  PUBLIC_DASH_FAIL,
  PUBLIC_DASH_REQUEST,
  PUBLIC_DASH_SUCCESS,
  PUBLIC_INFO_FAIL,
  PUBLIC_INFO_REQUEST,
  PUBLIC_INFO_SUCCESS,
  PUBLIC_SEARCH_FAIL,
  PUBLIC_SEARCH_REQUEST,
  PUBLIC_SEARCH_RESET,
  PUBLIC_SEARCH_SUCCESS,
} from "../constants/publicConstants";

export const publicCategoryListReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case PUBLIC_CATEGORY_LIST_REQUEST:
      return {
        loading: true,
      };

    case PUBLIC_CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case PUBLIC_CATEGORY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const visitorUserMenuReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case PUBLIC_DASH_REQUEST:
      return {
        loading: true,
      };

    case PUBLIC_DASH_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case PUBLIC_DASH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const publicInfoReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case PUBLIC_INFO_REQUEST:
      return {
        loading: true,
      };

    case PUBLIC_INFO_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case PUBLIC_INFO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const publicSearchUser = (state = { data: null }, action) => {
  switch (action.type) {
    case PUBLIC_SEARCH_REQUEST:
      return {
        loading: true,
      };

    case PUBLIC_SEARCH_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case PUBLIC_SEARCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PUBLIC_SEARCH_RESET:
      return {
        loading: false,
        data: null,
      };

    default:
      return state;
  }
};
