import React, { useEffect } from "react";
 

const AccountPackageScreen = () => {
 
  return (
    <>

<section id="pricing" class="bg-white">
    <div class="container">
        <h1 class="text-center">Tarifs des comptes</h1>


        <div class="spacer spacer-line border-primary">&nbsp;</div>
        <div class="spacer">&nbsp;</div>


        <div class="row">
            <div class="col-md-6">
                <div class="pricing-table">
                    <div class="pricing-table-title">
                        <h5 class="pricing-title bg-info-hover text-white">STARTER</h5>
                    </div>
                    <div class="pricing-table-price text-center bg-info">
                        <p class="title-font">
                         
                            <span class="pricing-price text-white">FREE</span>
                             
                        </p>
                    </div>
                    <div class="pricing-table-content">
                        <ul>
                            <li><strong>  Menu Classic</strong></li>
                            <li><strong>  Wi-Fi</strong></li>
                            <li><strong>  Social Media</strong></li>
                            <li><strong>   Galerie ( max 3 images) </strong></li>
                            <li><strong>  Promo / event</strong></li>
                            <li><strong>  livraison et mode de paiement</strong></li>
                            <li><strong>  Accessible via la barre de recherche</strong></li>
                            <li><strong className="cons">  Avec annonce publicitaire</strong></li>
                           
                        </ul>
                        <div class="pricing-table-button">
                            <a href="/register" class="btn btn-pack"><span>Créer un compte</span></a>
                        </div>
                    </div>
                </div>
            </div>

            

            <div class="col-md-6">
                <div class="pricing-table">
                    <div class="pricing-table-title">
                        <h5 class="pricing-title bg-primary-hover text-white">ENTERPRISE</h5>
                    </div>
                    <div class="pricing-table-price text-center bg-primary ">
                        <p class="title-font">
                            <span class="pricing-period text-white mr-1">From</span>
                            <span class="pricing-currency text-white">DT</span>
                            <span class="pricing-price text-white">360</span>
                            <span class="pricing-period text-white">/ Year</span>
                        </p>
                    </div>
                    <div class="pricing-table-content">
                        <ul>
                           <li><strong>  Menu Classic/<span className="pros">Professionnel avec des photos</span></strong></li>
                            <li><strong>  Wi-Fi</strong></li>
                            <li><strong>  Social Media</strong></li>
                            <li><strong>  Galerie <span className="pros">( max 30 images)</span> </strong></li>
                            <li><strong>  Promo / event</strong></li>
                            <li><strong>  livraison et mode de paiement</strong></li>
                            <li><strong className="pros">  Accessible via la barre de recherche "première resultat"</strong></li>
                            <li><strong className="pros">  Sans annonce publicitaire</strong></li>
                            <li><strong className="pros">  Signe professionnel   </strong></li>
                             
                        </ul>
                        <div class="pricing-table-button">
                        <a href="/contact" class="btn btn-pack"><span>Contact Nous</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div> 
</section>
    </>
  );
};

export default AccountPackageScreen;