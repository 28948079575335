import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getDiscount } from "../../../actions/discountActions";
import AddUserDiscount from "../../../components/UserDiscount/AddUserDiscount";

const UserDiscountScreen = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const getDis = useSelector((state) => state.getDiscount);
  const { error } = getDis;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  useEffect(() => {
    dispatch(getDiscount(userInfo.slug));
  }, [dispatch, userInfo.slug]);

  return (
    <main className="main-container">
      <div className="form-check mb-5 mt-3">
        <h1 style={{ textAlign: "center" }}> Modifier Promo </h1>
      </div>

      <AddUserDiscount />
    </main>
  );
};

export default UserDiscountScreen;
