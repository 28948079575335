export const MENU_CREAT_REQUEST = "MENU_CREAT_REQUEST";
export const MENU_CREAT_SUCCESS = "MENU_CREAT_SUCCESS";
export const MENU_CREAT_FAIL = "MENU_CREAT_FAIL";
export const MENU_CREAT_RESET = "MENU_CREAT_RESET";

export const MENU_GET_MENU_REQUEST = "MENU_GET_MENU_REQUEST";
export const MENU_GET_MENU_SUCCESS = "MENU_GET_MENU_SUCCESS";
export const MENU_GET_MENU_FAIL = "MENU_GET_MENU_FAIL";

export const MENU_UPDATE_REQUEST = "MENU_UPDATE_REQUEST";
export const MENU_UPDATE_SUCCESS = "MENU_UPDATE_SUCCESS";
export const MENU_UPDATE_FAIL = "MENU_UPDATE_FAIL";
export const MENU_UPDATE_RESET = "MENU_UPDATE_RESET";

export const MENU_LIST_REQUEST = "MENU_LIST_REQUEST";
export const MENU_LIST_SUCCESS = "MENU_LIST_SUCCESS";
export const MENU_LIST_FAIL = "MENU_LIST_FAIL";
export const MENU_LIST_RESET = "MENU_LIST_RESET";

export const MENU_DELETE_REQUEST = "MENU_DELETE_REQUEST";
export const MENU_DELETE_SUCCESS = "MENU_DELETE_SUCCESS";
export const MENU_DELETE_FAIL = "MENU_DELETE_FAIL";
export const MENU_DELETE_RESET = "MENU_DELETE_RESET";
