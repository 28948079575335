const apiUrl = "https://qrcode.tn/backend/public/api";
export const frontUrl = "https://qrcode.tn";

export const PUBLIC_INFO_API = apiUrl + "/public";
export const PUBLIC_CATEGORY_API = apiUrl + "/user/menu/";
export const PUBLIC_DASH_API = apiUrl + "/public/user/";
export const PUBLIC_SEARCH_API = apiUrl + "/public/search";

export const LOGIN_API = apiUrl + "/login";
export const REGISTER_API = apiUrl + "/register";
export const RESET_PASSWORD_API = apiUrl + "/reset/password";
export const EDIT_PROFIL_API = apiUrl + "/edit/user";
export const USER_DETAILS_API = apiUrl + "/user/details";
export const CHANGE_PASSWORD_API = apiUrl + "/change/password";

export const CATEGORY_API = apiUrl + "/category";
export const CATEGORY_EDIT_API = apiUrl + "/edit/category";
export const CATEGORY_RESTORE_API = apiUrl + "/restore/category";

export const PRODUCT_API = apiUrl + "/product";
export const PRODUCT_EDIT_API = apiUrl + "/edit/product";

export const MENU_API = apiUrl + "/menu";
export const MENU_CHANGE_STATUS_API = apiUrl + "/menu/change";

export const WIFI_API = apiUrl + "/wifi";

export const IMAGE_API = apiUrl + "/image";

export const SOCIAL_MEDIA_API = apiUrl + "/socialMedia";
export const SOCIAL_MEDIA_STATUS_API = apiUrl + "/social/media";

export const PAYMENT_API = apiUrl + "/feature";

export const DISCOUNT_API = apiUrl + "/discount";

//admin

export const GET_ALL_USERS_API = apiUrl + "/list/users";
export const CHANGE_PREMIUM_STATUS_API = apiUrl + "/change/premium/";
