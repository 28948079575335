import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editImage } from "../../actions/imageActions";
import {
  createSocialMedia,
  editSocialMedia,
} from "../../actions/socialMediaActions";
import { SOCIAL_MEDIA_UPDATE_RESET } from "../../constants/socialMediaConstants";
import { toastAlert } from "../../utils/alert";
import { listIcons } from "../../utils/socialMediaIcons";
import Loader from "../Loader";

const AddSocialMedia = () => {
  const dispatch = useDispatch();

  const socialMediaAdd = useSelector((state) => state.socialMediaCreate);
  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;

  const { loading, success } = socialMediaAdd;

  const [status, setStatus] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    icon: "",
    link: "",
    color: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    icon: "",
    link: "",
    color: "",
  });

  useEffect(() => {
    if (success) {
      toastAlert("Votre social media a été modifié");
      dispatch({ type: SOCIAL_MEDIA_UPDATE_RESET });
    }
  }, [dispatch, success]);

  const validForm = () => {
    return (
      validateField("icon", formData.icon) &&
      validateField("link", formData.link)
    );
  };

  useEffect(() => {
    setStatus(userInfo.socialmedia_status == "1" ? true : false);
  }, [userInfo.socialmedia_status]);

  const ajouterSocialMediaNow = () => {
    if (validForm()) {
      dispatch(createSocialMedia(formData));
    }
  };

  const handleUserInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    validateField(name, value);

    setFormData({ ...formData, link: value });
  };

  const validateField = (fieldName, value) => {
    let error,
      msg = "";
    switch (fieldName) {
      case "icon":
        error = value != null;
        msg = error ? "" : "L'icon est obligatoire";
        break;
      case "link":
        error = value != null;
        msg = error ? "" : "L'URL est obligatoire";
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [fieldName]: msg });
    return error;
  };

  const changeStatus = () => {
    dispatch(
      editSocialMedia({
        id: userInfo.id,
        status: status ? "0" : "1",
      })
    );
    setStatus(!status);
  };

  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <div className="label">Activer les réseaux sociaux</div>
        </Col>

        <Col>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              style={{
                height: "30px",
                width: "70px !important",
              }}
              checked={status}
              onChange={() => {
                changeStatus();
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="row">
        {listIcons.map((val) => (
          <div
            key={val.name}
            className={
              "customSocialMedia " +
              (val.name === formData.name ? "activeCustomSocialMedia" : "")
            }
            style={{ color: val.color }}
            onClick={() => {
              setFormData({
                name: val.name,
                icon: val.icon,
                color: val.color,
                link: formData.link,
              });
              console.log(formData);
            }}
          >
            <i className={val.icon}></i>
          </div>
        ))}
      </div>
      <div className="mb-3">
        <input
          type="text"
          id="exampleFormControlInput1"
          placeholder="ajouter votre lien"
          name="link"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["link"].length > 0 ? "inputError" : "")
          }
        />
        {formErrors["link"].length > 0 && (
          <p className="alert alert-danger descError">{formErrors["link"]}</p>
        )}
      </div>
      <div style={{ textAlign: "right" }}>
        <button
          className="light-btn addbtn"
          onClick={() => {
            console.log(loading);
            ajouterSocialMediaNow();
          }}
          disabled={loading}
        >
          {loading ? <Loader /> : "Sauvegarder"}
        </button>
      </div>
    </>
  );
};

export default AddSocialMedia;
