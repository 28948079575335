import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { listPublicCategories } from "../../actions/publicActions";
import Loader from "../../components/Loader";
import ClassicMenuScreen from "../../components/publicMenuScreen/ClassicMenuScreen";
import PremiumMenuScreen from "../../components/publicMenuScreen/PremiumMenuScreen";

const MenuScreen = () => {
  const params = useParams();
  const menuId = params.id;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const menuList = useSelector((state) => state.publicCategoryList);
  const { data, error, loading } = menuList;

  useEffect(() => {
    dispatch(listPublicCategories(menuId));
  }, [dispatch, menuId]);

  useEffect(() => {
    if (error) navigate("/");
  }, [error, navigate]);

  return (
    <div style={{ backgroundColor: "#3d3c3c" }}>
      <Helmet>
        <title> Menu</title>
      </Helmet>
      {!loading ? (
        data && data.menu && data.menu.is_premium == 1 ? (
          <PremiumMenuScreen />
        ) : (
          <ClassicMenuScreen />
        )
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default MenuScreen;
