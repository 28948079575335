import {
  SOCIAL_MEDIA_GET_SOCIAL_MEDIA_FAIL,
  SOCIAL_MEDIA_GET_SOCIAL_MEDIA_REQUEST,
  SOCIAL_MEDIA_GET_SOCIAL_MEDIA_SUCCESS,
  SOCIAL_MEDIA_CREAT_FAIL,
  SOCIAL_MEDIA_CREAT_REQUEST,
  SOCIAL_MEDIA_CREAT_SUCCESS,
  SOCIAL_MEDIA_DELETE_FAIL,
  SOCIAL_MEDIA_DELETE_REQUEST,
  SOCIAL_MEDIA_DELETE_SUCCESS,
  SOCIAL_MEDIA_LIST_FAIL,
  SOCIAL_MEDIA_LIST_REQUEST,
  SOCIAL_MEDIA_LIST_SUCCESS,
  SOCIAL_MEDIA_UPDATE_FAIL,
  SOCIAL_MEDIA_UPDATE_REQUEST,
  SOCIAL_MEDIA_UPDATE_SUCCESS,
  SOCIAL_MEDIA_CREAT_RESET,
  SOCIAL_MEDIA_UPDATE_RESET,
  SOCIAL_MEDIA_DELETE_RESET,
  SOCIAL_MEDIA_LIST_RESET,
} from "../constants/socialMediaConstants";

export const socialMediaCreatReducer = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_MEDIA_CREAT_REQUEST:
      return {
        loading: true,
      };

    case SOCIAL_MEDIA_CREAT_SUCCESS:
      return {
        loading: false,
        success: true,
        socialMedia: action.payload,
      };
    case SOCIAL_MEDIA_CREAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SOCIAL_MEDIA_CREAT_RESET:
      return {};
    default:
      return state;
  }
};

export const socialMediaUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_MEDIA_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case SOCIAL_MEDIA_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        socialMedia: action.payload,
      };
    case SOCIAL_MEDIA_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SOCIAL_MEDIA_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const socialMediaDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_MEDIA_DELETE_REQUEST:
      return {
        loading: true,
      };

    case SOCIAL_MEDIA_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        socialMedia: action.payload,
      };
    case SOCIAL_MEDIA_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SOCIAL_MEDIA_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const socialMediaListMyReducer = (
  state = { socialMedias: [] },
  action
) => {
  switch (action.type) {
    case SOCIAL_MEDIA_LIST_REQUEST:
      return {
        loading: true,
        socialMedias: [],
      };

    case SOCIAL_MEDIA_LIST_SUCCESS:
      return {
        loading: false,
        socialMedias: action.payload,
      };
    case SOCIAL_MEDIA_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SOCIAL_MEDIA_LIST_RESET:
      return { socialMedias: [] };

    default:
      return state;
  }
};

export const getSocialMediaReducer = (state = { socialMedias: [] }, action) => {
  switch (action.type) {
    case SOCIAL_MEDIA_GET_SOCIAL_MEDIA_REQUEST:
      return {
        loading: true,
        socialMedias: [],
      };

    case SOCIAL_MEDIA_GET_SOCIAL_MEDIA_SUCCESS:
      return {
        loading: false,
        socialMedias: action.payload,
      };
    case SOCIAL_MEDIA_GET_SOCIAL_MEDIA_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
