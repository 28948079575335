import {
  PRODUCT_CREAT_FAIL,
  PRODUCT_CREAT_REQUEST,
  PRODUCT_CREAT_RESET,
  PRODUCT_CREAT_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_RESET,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_RESET,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_SUCCESS,
} from "../constants/productConstants";

export const productCreatReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREAT_REQUEST:
      return {
        loading: true,
      };

    case PRODUCT_CREAT_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case PRODUCT_CREAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PRODUCT_CREAT_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case PRODUCT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case PRODUCT_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PRODUCT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return {
        loading: true,
      };

    case PRODUCT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case PRODUCT_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PRODUCT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const productListMyReducer = (state = { product: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return {
        loading: true,
      };

    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        product: action.payload,
      };
    case PRODUCT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PRODUCT_LIST_RESET:
      return { product: [] };

    default:
      return state;
  }
};
