import axios from "axios";
import {
  CATEGORY_CREAT_FAIL,
  CATEGORY_CREAT_REQUEST,
  CATEGORY_CREAT_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_RESTORE_FAIL,
  CATEGORY_RESTORE_REQUEST,
  CATEGORY_RESTORE_RESET,
  CATEGORY_RESTORE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
} from "../constants/categoryConstants";
import {
  CATEGORY_API,
  CATEGORY_RESTORE_API,
  CATEGORY_EDIT_API,
} from "../utils/apiConfig";

export const createCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_CREAT_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(CATEGORY_API, category, config);

    dispatch({
      type: CATEGORY_CREAT_SUCCESS,
      payload: data,
    });
    dispatch(listMyCategories());
    // dispatch({
    //   type: CART_RESET,
    // });
  } catch (error) {
    dispatch({
      type: CATEGORY_CREAT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const editCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "multipart/form-data",

        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const id = !category.id ? category.get("id") : category.id;
    const { data } = await axios.post(
      CATEGORY_EDIT_API + "/" + id,
      category,
      config
    );

    dispatch({
      type: CATEGORY_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch(listMyCategories());
    // dispatch({
    //   type: CART_RESET,
    // });
  } catch (error) {
    dispatch({
      type: CATEGORY_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const deleteCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      CATEGORY_API + "/" + category.id,
      config
    );

    dispatch({
      type: CATEGORY_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listMyCategories());
  } catch (error) {
    dispatch({
      type: CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const listMyCategories = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
      categoryListMy: { categories },
    } = getState();
    dispatch({
      type: CATEGORY_LIST_REQUEST,
      payload: categories,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(CATEGORY_API, config);

    dispatch({
      type: CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const restoreCategory = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_RESTORE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(CATEGORY_RESTORE_API, {}, config);

    dispatch({
      type: CATEGORY_RESTORE_SUCCESS,
      payload: data,
    });
    dispatch(listMyCategories());
    setTimeout(() => {
      dispatch({
        type: CATEGORY_RESTORE_RESET,
        payload: {},
      });
    }, 500);
    // dispatch({
    //   type: CART_RESET,
    // });
  } catch (error) {
    dispatch({
      type: CATEGORY_RESTORE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
