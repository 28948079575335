export const IMAGE_CREAT_REQUEST = "IMAGE_CREAT_REQUEST";
export const IMAGE_CREAT_SUCCESS = "IMAGE_CREAT_SUCCESS";
export const IMAGE_CREAT_FAIL = "IMAGE_CREAT_FAIL";
export const IMAGE_CREAT_RESET = "IMAGE_CREAT_RESET";

export const IMAGE_GET_IMAGE_REQUEST = "IMAGE_GET_IMAGE_REQUEST";
export const IMAGE_GET_IMAGE_SUCCESS = "IMAGE_GET_IMAGE_SUCCESS";
export const IMAGE_GET_IMAGE_FAIL = "IMAGE_GET_IMAGE_FAIL";

export const IMAGE_UPDATE_REQUEST = "IMAGE_UPDATE_REQUEST";
export const IMAGE_UPDATE_SUCCESS = "IMAGE_UPDATE_SUCCESS";
export const IMAGE_UPDATE_FAIL = "IMAGE_UPDATE_FAIL";
export const IMAGE_UPDATE_RESET = "IMAGE_UPDATE_RESET";

export const IMAGE_LIST_REQUEST = "IMAGE_LIST_REQUEST";
export const IMAGE_LIST_SUCCESS = "IMAGE_LIST_SUCCESS";
export const IMAGE_LIST_FAIL = "IMAGE_LIST_FAIL";
export const IMAGE_LIST_RESET = "IMAGE_LIST_RESET";

export const IMAGE_DELETE_REQUEST = "IMAGE_DELETE_REQUEST";
export const IMAGE_DELETE_SUCCESS = "IMAGE_DELETE_SUCCESS";
export const IMAGE_DELETE_FAIL = "IMAGE_DELETE_FAIL";
export const IMAGE_DELETE_RESET = "IMAGE_DELETE_RESET";
