import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProduct } from "../../actions/productActions";
import { toastAlert } from "../../utils/alert";
import Loader from "../Loader";

const AddProduct = ({ menuId }) => {
  const dispatch = useDispatch();
  const createProd = useSelector((state) => state.productCreate);
  const listCat = useSelector((state) => state.categoryListMy);

  const { categories } = listCat;
  const {
    loading: loadingProd,
    // error: errorProd,
    success: successProd,
  } = createProd;

  const [satusProduct, setsatusProduct] = useState(false);

  const [formData, setFormData] = useState({
    desc: "",
    cat: "",
    price: "",
    name: "",
  });

  const [formErrors, setFormErrors] = useState({
    cat: "",
    price: "",
    name: "",
  });

  useEffect(() => {
    if (successProd && satusProduct) {
      setFormData({
        desc: "",
        cat: "",
        price: "",
        name: "",
      });
      toastAlert("Votre produit a été ajouté");
      setsatusProduct(false);
    }
  }, [successProd, formData, satusProduct]);

  const validForm = () => {
    return (
      validateField("cat", formData.cat) &&
      validateField("name", formData.name) &&
      validateField("price", formData.price)
    );
  };

  const addProduct = () => {
    if (validForm()) {
      setsatusProduct(true);
      dispatch(
        createProduct({
          name: formData.name,
          description: formData.desc,
          price: formData.price,
          category_id: formData.cat,
          menu_id: menuId,
        })
      );
    }
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    validateField(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (fieldName, value) => {
    let error,
      msg = "";
    switch (fieldName) {
      case "name":
        error = value.length >= 1;
        msg = error ? "" : "Le nom est obligatoire";
        break;
      case "cat":
        error = value.length >= 1;
        msg = error ? "" : "la Categorie est obligatoire";
        break;
      case "price":
        error = parseFloat(value);
        msg = error ? "" : "Le prix doit être un nombre";
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [fieldName]: msg });
    return error;
  };

  return (
    <>
      <div   className=" mt-5 label">
        Ajouter Produit
      </div>
      <div className="mb-3 mt-4">
        <select
          name="cat"
          value={formData.cat}
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " + (formErrors["cat"].length > 0 ? "inputError" : "")
          }
        >
          <option value="">Choisissez la catégorie</option>
          {categories &&
            categories.map((cat) => (
              <option value={cat.id} key={cat.id}>
                {cat.name}
              </option>
            ))}
        </select>
        {formErrors["cat"].length > 0 && (
          <p className="alert alert-danger descError">{formErrors["cat"]}</p>
        )}
      </div>
      <div className="mb-3">
        <input
          type="text"
          id="exampleFormControlInput1"
          placeholder="nom : Pizza neptune"
          value={formData.name}
          name="name"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["name"].length > 0 ? "inputError" : "")
          }
        />
        {formErrors["name"].length > 0 && (
          <p className="alert alert-danger descError">{formErrors["name"]}</p>
        )}
      </div>
      <div className="mb-3">
        <input
          type="text"
          id="exampleFormControlInput1"
          placeholder="description : Pizza Fine et Rectangulaire avec 140 g de thon"
          value={formData.desc}
          name="desc"
          onChange={(e) => handleUserInput(e)}
          className={"form-control "}
        />
      </div>
      <div className=" input-group mb-3">
        <input
          type="text"
          id="exampleFormControlInput1"
          placeholder="Prix : ' 12 '"
          value={formData.price}
          name="price"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["price"].length > 0 ? "inputError" : "")
          }
        />
        <div className="input-group-append">
          <label
            className="input-group-text"
            style={{ borderRadius: "0 5px 5px 0" }}
          >
            DT
          </label>
        </div>
      </div>
      {formErrors["price"].length > 0 && (
        <p className="alert alert-danger descError">{formErrors["price"]}</p>
      )}
      <button
        className="light-btn"
        onClick={() => {
          addProduct();
        }}
        disabled={loadingProd}
      >
        {loadingProd ? <Loader /> : "Ajouter"}
      </button>
    </>
  );
};

export default AddProduct;
