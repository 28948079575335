import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { listMyImages } from "../../../actions/imageActions";
import AddUserImage from "../../../components/UserImage/AddUserImage";
import UserImageList from "../../../components/UserImage/UserImageList";

const UserImageScreen = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const listMyImage = useSelector((state) => state.imageListMy);
  const { error, images, loading } = listMyImage;

  useEffect(() => {
    dispatch(listMyImages());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  return (
    <main className="main-container">
      <div className="form-check mb-5 mt-3">
        <h1 style={{ textAlign: "center" }}> Galerie</h1>
      </div>

      <AddUserImage />

      <UserImageList />
    </main>
  );
};

export default UserImageScreen;
