import {
  MENU_GET_MENU_FAIL,
  MENU_GET_MENU_REQUEST,
  MENU_GET_MENU_SUCCESS,
  MENU_CREAT_FAIL,
  MENU_CREAT_REQUEST,
  MENU_CREAT_RESET,
  MENU_CREAT_SUCCESS,
  MENU_DELETE_FAIL,
  MENU_DELETE_REQUEST,
  MENU_DELETE_RESET,
  MENU_DELETE_SUCCESS,
  MENU_LIST_FAIL,
  MENU_LIST_REQUEST,
  MENU_LIST_RESET,
  MENU_LIST_SUCCESS,
  MENU_UPDATE_FAIL,
  MENU_UPDATE_REQUEST,
  MENU_UPDATE_RESET,
  MENU_UPDATE_SUCCESS,
} from "../constants/menuConstants";

export const menuCreatReducer = (state = {}, action) => {
  switch (action.type) {
    case MENU_CREAT_REQUEST:
      return {
        loading: true,
      };

    case MENU_CREAT_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case MENU_CREAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MENU_CREAT_RESET:
      return {};
    default:
      return state;
  }
};

export const menuUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MENU_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case MENU_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case MENU_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MENU_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const menuDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MENU_DELETE_REQUEST:
      return {
        loading: true,
      };

    case MENU_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case MENU_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MENU_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const menuListMyReducer = (state = { menus: [] }, action) => {
  switch (action.type) {
    case MENU_LIST_REQUEST:
      return {
        loading: true,
      };

    case MENU_LIST_SUCCESS:
      return {
        loading: false,
        menus: action.payload,
      };
    case MENU_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MENU_LIST_RESET:
      return { menus: [] };

    default:
      return state;
  }
};

export const getMenuReducer = (state = {}, action) => {
  switch (action.type) {
    case MENU_GET_MENU_REQUEST:
      return {
        loading: true,
      };

    case MENU_GET_MENU_SUCCESS:
      return {
        loading: false,
        menu: action.payload,
      };
    case MENU_GET_MENU_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
