export const PUBLIC_CATEGORY_LIST_REQUEST = "PUBLIC_CATEGORY_LIST_REQUEST";
export const PUBLIC_CATEGORY_LIST_SUCCESS = "PUBLIC_CATEGORY_LIST_SUCCESS";
export const PUBLIC_CATEGORY_LIST_FAIL = "PUBLIC_CATEGORY_LIST_FAIL";

export const PUBLIC_DASH_REQUEST = "PUBLIC_DASH_REQUEST";
export const PUBLIC_DASH_SUCCESS = "PUBLIC_DASH_SUCCESS";
export const PUBLIC_DASH_FAIL = "PUBLIC_DASH_FAIL";

export const PUBLIC_INFO_REQUEST = "PUBLIC_INFO_REQUEST";
export const PUBLIC_INFO_SUCCESS = "PUBLIC_INFO_SUCCESS";
export const PUBLIC_INFO_FAIL = "PUBLIC_INFO_FAIL";

export const PUBLIC_SEARCH_REQUEST = "PUBLIC_SEARCH_REQUEST";
export const PUBLIC_SEARCH_SUCCESS = "PUBLIC_SEARCH_SUCCESS";
export const PUBLIC_SEARCH_FAIL = "PUBLIC_SEARCH_FAIL";
export const PUBLIC_SEARCH_RESET = "PUBLIC_SEARCH_RESET";
