import axios from "axios";
import {
  WIFI_CREAT_FAIL,
  WIFI_CREAT_REQUEST,
  WIFI_CREAT_SUCCESS,
  WIFI_DELETE_FAIL,
  WIFI_DELETE_REQUEST,
  WIFI_DELETE_SUCCESS,
  WIFI_GET_FAIL,
  WIFI_GET_REQUEST,
  WIFI_GET_SUCCESS,
  WIFI_LIST_FAIL,
  WIFI_LIST_REQUEST,
  WIFI_LIST_SUCCESS,
  WIFI_UPDATE_FAIL,
  WIFI_UPDATE_REQUEST,
  WIFI_UPDATE_SUCCESS,
} from "../constants/wifiConstants";

import { WIFI_API } from "../utils/apiConfig";

export const createWifi = (wifi) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WIFI_CREAT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(WIFI_API, wifi, config);

    dispatch({
      type: WIFI_CREAT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WIFI_CREAT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const editWifi = (wifi) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WIFI_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(WIFI_API + "/" + wifi.id, wifi, config);

    dispatch({
      type: WIFI_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WIFI_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const deleteWifi = (wifi) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WIFI_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(WIFI_API + "/" + wifi.id, config);

    dispatch({
      type: WIFI_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WIFI_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getWifi = (wifi) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WIFI_GET_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(WIFI_API + "/" + wifi, config);

    dispatch({
      type: WIFI_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WIFI_GET_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const listMyWifi = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: WIFI_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(WIFI_API, config);

    dispatch({
      type: WIFI_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WIFI_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
