import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../actions/userActions";
import { useNavigate } from "react-router";
import { frontUrl } from "../../utils/apiConfig";
import { changePremiumStatus } from "../../actions/premiumActions";
import { toastAlert } from "../../utils/alert";

export default function UsersList() {
  const copyLogin = (token) => {
    console.log(frontUrl + "/login/token/" + token);
    navigator.clipboard.writeText(frontUrl + "/login/token/" + token);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderCell: (params) => {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              style={{
                width: "50px",
                height: "50px",
              }}
              src={
                params.row.image != null
                  ? params.row.image
                  : "/images/avatar.png"
              }
              alt={params.row.name}
            />
          </div>
        );
      },
    },
    { field: "name", headerName: "Full name", width: 150 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "nbrViews", headerName: "Statistique", width: 70 },
    {
      field: "email",
      headerName: "Email",
      width: 280,
    },
    {
      field: "qrcode",
      headerName: "Qrcode",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <img
              style={{
                width: "70px",
                height: "40px",
              }}
              src={params.row.qrcode}
              alt={params.row.name}
            />
          </>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      width: 100,
    },
    {
      field: "Premium",
      headerName: "Premium",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div>
              {params.row.is_premium == 1 ? (
                <button
                  className="btn btn-success action-btn"
                  style={{
                    backgroundColor: "green !important",
                  }}
                  onClick={() => changeNow(params.row.id)}
                >
                  is Premuim
                </button>
              ) : (
                <button
                  style={{
                    backgroundColor: "red !important",
                  }}
                  className="btn btn-error action-btn"
                  onClick={() => changeNow(params.row.id)}
                >
                  is not Premuim
                </button>
              )}
            </div>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div>
              <button
                className="btn btn-primary action-btn"
                onClick={() => copyLogin(params.row.token)}
              >
                Se connecter
              </button>
            </div>
          </>
        );
      },
    },
  ];

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const userAll = useSelector((state) => state.userAll);
  const { loading, error, users } = userAll;
  const changeUserPremiumStatus = useSelector(
    (state) => state.changeUserPremiumStatus
  );
  const {
    loading: loadingchangeStatus,
    error: errorchangeStatus,
    success,
    data,
  } = changeUserPremiumStatus;

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (error) navigate("/");
  }, [error, navigate]);
  useEffect(() => {
    if (success) toastAlert("status a été modifiée avec succes");
  }, [success, navigate]);
  const changeNow = (id) => {
    dispatch(changePremiumStatus(id));
  };

  return (
    <div className="container">
      <h1 className="mt-5 mb-5">User List </h1>
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          loading={loading}
          rows={users}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
        />
      </div>
    </div>
  );
}
