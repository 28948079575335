import {
  CATEGORY_CREAT_FAIL,
  CATEGORY_CREAT_REQUEST,
  CATEGORY_CREAT_RESET,
  CATEGORY_CREAT_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_RESET,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_RESET,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_RESTORE_FAIL,
  CATEGORY_RESTORE_REQUEST,
  CATEGORY_RESTORE_RESET,
  CATEGORY_RESTORE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_RESET,
  CATEGORY_UPDATE_SUCCESS,
} from "../constants/categoryConstants";

export const categoryCreatReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_CREAT_REQUEST:
      return {
        loading: true,
      };

    case CATEGORY_CREAT_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case CATEGORY_CREAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CATEGORY_CREAT_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case CATEGORY_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case CATEGORY_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CATEGORY_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return {
        loading: true,
      };

    case CATEGORY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case CATEGORY_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CATEGORY_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const categoryListMyReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return {
        loading: true,
        categories: action.payload,
      };

    case CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
      };
    case CATEGORY_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CATEGORY_LIST_RESET:
      return { categories: [] };

    default:
      return state;
  }
};

export const restoreCreatReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_RESTORE_REQUEST:
      return {
        loading: true,
      };

    case CATEGORY_RESTORE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case CATEGORY_RESTORE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CATEGORY_RESTORE_RESET:
      return {};

    default:
      return state;
  }
};
