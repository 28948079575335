import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editDiscount } from "../../actions/discountActions";
import { toastAlert } from "../../utils/alert";
import Loader from "../Loader";

const AddUserDiscount = ({ discountid }) => {
  const dispatch = useDispatch();

  const discountUpdate = useSelector((state) => state.updateDiscount);

  const getDiscount = useSelector((state) => state.getDiscount);

  const {
    // loading: loadingGet,
    discount,
    // success: successGet,
  } = getDiscount;

  const { loading, success } = discountUpdate;

  const [status, setStatus] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    url: "",
    urlImage: "",
  });

  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    image: "",
  });

  const [loadings, setLoadingStatus] = useState(false);

  useEffect(() => {
    if (discount) {
      setFormData({
        title: discount.title,
        description: discount.description,
        urlImage: discount.image,
        url: discount.url,
      });
      setStatus(discount.status == "1" ? true : false);
    }
  }, [discount]);

  useEffect(() => {
    if (success) {
      toastAlert("Votre promo a été modifié");
    }
  }, [success]);

  const validForm = () => {
    return (
      validateField("title", formData.title) &&
      validateField("description", formData.description)
    );
  };

  const editDiscountNow = async () => {
    if (validForm()) {
      let fileSize = 0;
      if (formData.image) fileSize = formData.image.size / 1024;
      if (fileSize < 4120) {
        setLoadingStatus(true);
        try {
          const formDatas = new FormData();
          if (formData.image) {
            const options = {
              maxSizeMB: 0.2,
            };
            const compressedFile = await imageCompression(
              formData.image,
              options
            );
            console.log(compressedFile.size / 1024);
            formDatas.append("image", compressedFile);
          }

          formDatas.append("title", formData.title);
          formDatas.append("description", formData.description);
          formDatas.append("url", formData.url);
          formDatas.append("status", status ? 1 : 0);
          dispatch(editDiscount(formDatas, discount.id));
        } catch (err) {
          console.log(err.message);
        }
        setLoadingStatus(false);
      } else {
        toastAlert("L'image doit être de taille inférieure 4Mo", "error");
      }
    }
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    if (name === "title" || name === "description" || name === "url") {
      const value = e.target.value;

      validateField(name, value);
      setFormData({ ...formData, [name]: value });
    } else {
      const value = e.target.files[0];

      validateField("image", value);
      setFormData({ ...formData, image: value });
    }
  };

  const validateField = (fieldName, value) => {
    let error,
      msg = "";
    switch (fieldName) {
      case "title":
        error = value.length >= 1 && value.length < 70;
        msg = error ? "" : "Le titre est obligatoire";
        break;
      case "description":
        error = value.length >= 1 && value.length < 200;
        msg = error ? "" : "Description est obligatoire";
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [fieldName]: msg });
    return error;
  };

  const changeStatus = () => {
    setStatus(!status);
  };
  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <div className="label">Activer Le Promo</div>
        </Col>

        <Col>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              style={{
                height: "30px",
                width: "70px !important",
              }}
              checked={status}
              onChange={() => {
                changeStatus();
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="mb-3">
        <input
          type="text"
          placeholder="titre de votre promo "
          value={formData.title}
          name="title"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["title"].length > 0 ? "inputError" : "")
          }
        />
        {formErrors["title"].length > 0 && (
          <p className="alert alert-danger descError">{formErrors["title"]}</p>
        )}
      </div>
      <div className="mb-3">
        <input
          type="text"
          placeholder="description promo"
          value={formData.description}
          name="description"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["description"].length > 0 ? "inputError" : "")
          }
        />
        {formErrors["description"].length > 0 && (
          <p className="alert alert-danger descError">
            {formErrors["description"]}
          </p>
        )}
      </div>
      <div className="mb-3">
        <input
          type="text"
          placeholder="url de promo"
          value={formData.url}
          name="url"
          onChange={(e) => handleUserInput(e)}
          className="form-control"
        />
      </div>
      <div className="mb-3">
        <input
          type="file"
          accept="images/*"
          id="exampleFormControlInput1"
          name="image"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["image"].length > 0 ? "inputError" : "")
          }
        />
        {formErrors["image"].length > 0 && (
          <p className="alert alert-danger descError">{formErrors["image"]}</p>
        )}
      </div>

      <button
        className="light-btn addbtn"
        onClick={() => {
          editDiscountNow();
        }}
        disabled={loading || loadings}
      >
        {loading || loadings ? <Loader /> : "Sauvegarder"}
      </button>

      <div
        className="mb-5"
        style={{
          marginTop: "100px",
          textAlign: "center",
          backgroundColor: "#ffcd1b",
          padding: "20px",
        }}
      >
        <h3> {discount && discount.title}</h3>

        <p> {discount && discount.description}</p>
        <div>
          {discount && (
            <img
              style={{
                width: "100%",
              }}
              src={discount.image}
            />
          )}
          {discount && discount.url && (
            <div className="text-center mt-3">
              <a target="_blank" href={discount.url}>
                <button className="buttonn-design"> Voir plus </button>
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddUserDiscount;
