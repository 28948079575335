import React, { useEffect } from "react";
 

const ContactScreen = () => {
 
  return (
    <>

<h1 className="title">
L'équipe Qrcode.tn est toujours <br></br>disponible pour vous aider 24/7
        </h1>

      <section className="main-container text-center">
         <div>
         <button  
                className="buttonn-design">
                    
                    <a

                      href="https://www.facebook.com/profile.php?id=100087847434643"
                      target="_blank"
                    >
                      <i className={"fab fa-facebook"}></i> Facebbok 
                    </a>
                  </button>
                  </div>

                  



                  <div>
                  <button  
                className="buttonn-design">
                    
                    <a
                      
                      href="https://www.instagram.com/qrcode_tn/"
                      target="_blank"
                    >
                      <i className={"fab fa-instagram"}></i> Instagram 
                    </a>
                  </button>
                  </div>


                  <div>
                  <button  
                className="buttonn-design">
                    
                    <a
                      
                      href="mailto:qrcodetn@gmail.com"
                      target="_blank"
                    >
                      <i className={"fa fa-envelope"}></i> E-mail 
                    </a>
                  </button>
                  </div>


                  <div>
                  <button  
                className="buttonn-design">
                    
                    <a
                      
                      href="https://wa.me/21626001224"
                      target="_blank"
                    >
                      <i className={"fab fa-whatsapp"}></i> WhatsApp 
                    </a>
                  </button>
                  </div>


                  <div>
                  <button  
                className="buttonn-design">
                    
                    <a
                      
                      href="tel:26001224"
                      target="_blank"
                    >
                      <i className={"fa fa-phone"}></i> Téléphone 
                    </a>
                  </button>
                  


                  </div>



       
        
      </section>
    </>
  );
};

export default ContactScreen;