import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { userResetPassword } from "../../actions/userActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const ResetPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const userReset = useSelector((state) => state.userResetPassword);
  const { loading, error } = userReset;

  const submitHandler = (e) => {
    e.preventDefault();
    //Dispatch Login
    dispatch(userResetPassword(email));
  };
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Réinitialiser le mot de passe</h1>

      <div>
        {error && <Message variant="danger">{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="email">
            <Form.Label> Adresse Email </Form.Label>
            <Form.Control
              type="email"
              placeholder="nom@exemple.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Button
            type="submit"
            style={{ marginTop: "15px" }}
            className="button-design"
            variant=" "
          >
            {" "}
            Envoyer le mot de passe
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
