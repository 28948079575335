import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getWifi } from "../../actions/wifiActions";
import { toastAlert } from "../../utils/alert";
import Loader from "../../components/Loader";

const WifiScreen = () => {
  let navigate = useNavigate();

  const params = useParams();
  const wifiId = params.id;

  const dispatch = useDispatch();

  const getWifiNow = useSelector((state) => state.getWifiNow);
  const { loading, error, wifi } = getWifiNow;

  useEffect(() => {
    dispatch(getWifi(wifiId));
  }, [dispatch, wifiId]);

  useEffect(() => {
    console.log(error);
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  return (
    <main className="main-container">
      <Helmet>
        <title>Wifi</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <div className="text-center">
          <div className="form-check mb-5 mt-5">
            <h1> Wi-Fi : {wifi && wifi.name}</h1>
          </div>

          <div>
                <h3>{wifi && wifi.password}</h3>   
          </div>
          <div>

          
            <button
              className="buttonn-design"
              style={{ width: "auto" }}
              onClick={() => {
                if (wifi.password) navigator.clipboard.writeText(wifi.password);
                toastAlert("mot de passe a été copié");
              }}
            >
              Copier le mot de passe
            </button>
          </div>
        </div>
      )}
    </main>
  );
};

export default WifiScreen;
