import axios from "axios";
import {
  SOCIAL_MEDIA_GET_SOCIAL_MEDIA_FAIL,
  SOCIAL_MEDIA_GET_SOCIAL_MEDIA_REQUEST,
  SOCIAL_MEDIA_GET_SOCIAL_MEDIA_SUCCESS,
  SOCIAL_MEDIA_CREAT_FAIL,
  SOCIAL_MEDIA_CREAT_REQUEST,
  SOCIAL_MEDIA_CREAT_SUCCESS,
  SOCIAL_MEDIA_DELETE_FAIL,
  SOCIAL_MEDIA_DELETE_REQUEST,
  SOCIAL_MEDIA_DELETE_SUCCESS,
  SOCIAL_MEDIA_LIST_FAIL,
  SOCIAL_MEDIA_LIST_REQUEST,
  SOCIAL_MEDIA_LIST_SUCCESS,
  SOCIAL_MEDIA_UPDATE_FAIL,
  SOCIAL_MEDIA_UPDATE_REQUEST,
  SOCIAL_MEDIA_UPDATE_SUCCESS,
} from "../constants/socialMediaConstants";
import { SOCIAL_MEDIA_API, SOCIAL_MEDIA_STATUS_API } from "../utils/apiConfig";

export const createSocialMedia =
  (socialMedia) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SOCIAL_MEDIA_CREAT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          enctype: "multipart/form-data",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(SOCIAL_MEDIA_API, socialMedia, config);

      dispatch({
        type: SOCIAL_MEDIA_CREAT_SUCCESS,
        payload: data,
      });

      dispatch(listMySocialMedias());
    } catch (error) {
      dispatch({
        type: SOCIAL_MEDIA_CREAT_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const editSocialMedia = (socialMedia) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SOCIAL_MEDIA_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      SOCIAL_MEDIA_STATUS_API + "/" + socialMedia.id,
      socialMedia,
      config
    );

    dispatch({
      type: SOCIAL_MEDIA_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SOCIAL_MEDIA_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const deleteSocialMedia =
  (socialMedia) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SOCIAL_MEDIA_DELETE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        SOCIAL_MEDIA_API + "/" + socialMedia,
        config
      );

      dispatch({
        type: SOCIAL_MEDIA_DELETE_SUCCESS,
        payload: data,
      });
      dispatch(listMySocialMedias());
    } catch (error) {
      dispatch({
        type: SOCIAL_MEDIA_DELETE_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const getSocialMedia = (socialMedia) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SOCIAL_MEDIA_GET_SOCIAL_MEDIA_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      SOCIAL_MEDIA_API + "/" + socialMedia,
      config
    );

    dispatch({
      type: SOCIAL_MEDIA_GET_SOCIAL_MEDIA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SOCIAL_MEDIA_GET_SOCIAL_MEDIA_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const listMySocialMedias = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SOCIAL_MEDIA_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(SOCIAL_MEDIA_API, config);

    dispatch({
      type: SOCIAL_MEDIA_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SOCIAL_MEDIA_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
