import {
  WIFI_CREAT_FAIL,
  WIFI_CREAT_RESET,
  WIFI_CREAT_SUCCESS,
  WIFI_DELETE_FAIL,
  WIFI_DELETE_REQUEST,
  WIFI_DELETE_RESET,
  WIFI_DELETE_SUCCESS,
  WIFI_LIST_FAIL,
  WIFI_LIST_REQUEST,
  WIFI_LIST_RESET,
  WIFI_LIST_SUCCESS,
  WIFI_UPDATE_FAIL,
  WIFI_UPDATE_REQUEST,
  WIFI_UPDATE_RESET,
  WIFI_UPDATE_SUCCESS,
  WIFI_CREAT_REQUEST,
  WIFI_GET_REQUEST,
  WIFI_GET_SUCCESS,
  WIFI_GET_FAIL,
  WIFI_GET_RESET,
} from "../constants/wifiConstants";

export const wifiCreatReducer = (state = {}, action) => {
  switch (action.type) {
    case WIFI_CREAT_REQUEST:
      return {
        loading: true,
      };

    case WIFI_CREAT_SUCCESS:
      return {
        loading: false,
        success: true,
        wifi: action.payload,
      };
    case WIFI_CREAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case WIFI_CREAT_RESET:
      return {};
    default:
      return state;
  }
};

export const wifiUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case WIFI_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case WIFI_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        wifi: action.payload,
      };
    case WIFI_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case WIFI_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const wifiDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WIFI_DELETE_REQUEST:
      return {
        loading: true,
      };

    case WIFI_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        wifi: action.payload,
      };
    case WIFI_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case WIFI_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const wifiListMyReducer = (state = { wifis: [] }, action) => {
  switch (action.type) {
    case WIFI_LIST_REQUEST:
      return {
        loading: true,
      };

    case WIFI_LIST_SUCCESS:
      return {
        loading: false,
        wifis: action.payload,
      };
    case WIFI_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case WIFI_LIST_RESET:
      return { wifis: [] };

    default:
      return state;
  }
};

export const getWifiReducer = (state = {}, action) => {
  switch (action.type) {
    case WIFI_GET_REQUEST:
      return {
        loading: true,
      };

    case WIFI_GET_SUCCESS:
      return {
        loading: false,
        wifi: action.payload,
      };
    case WIFI_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case WIFI_GET_RESET:
      return {};

    default:
      return state;
  }
};