import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getImage } from "../../actions/imageActions";
import Loader from "../../components/Loader";
import { Image } from "react-bootstrap";

const ImageScreen = () => {
  let navigate = useNavigate();

  const params = useParams();
  const imageId = params.id;

  const dispatch = useDispatch();

  const getImageNow = useSelector((state) => state.getImageNow);
  const { loading, error, images } = getImageNow;
  console.log(images);
  useEffect(() => {
    dispatch(getImage(imageId));
  }, [dispatch, imageId]);

  useEffect(() => {
    console.log(error);
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  return (
    <main className="main-container">
      <Helmet>
        <title>Liste des photos</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="form-check "></div>
          <div
            className="container"
            style={{ textAlign: "center", position: "relative" }}
          >
            <Carousel infiniteLoop={true} autoPlay={false} centerMode={true}>
              {images &&
                images.map((image, index) => (
                  <div key={index * 21}>
                    <Image
                      src={image.image}
                      style={{ width: "auto !important", maxHeight: "1080px" }}
                    />
                  </div>
                ))}
            </Carousel>
          </div>
        </>
      )}
    </main>
  );
};

export default ImageScreen;
