import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateUserPassword } from "../../actions/userActions";
import Loader from "../Loader";

const ChangePasswordScreen = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const userRegist = useSelector((state) => state.userChangePassword);
  const { loading, error } = userRegist;
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(error);
    if (error && error.length > 0) {
      console.log("eee");
      setFormErrors({
        ...formErrors,
        currentPassword: "Merci de vérifier votre mot de passe",
      });
    }
  }, [error]);

  const validForm = () => {
    return (
      validateField("currentPassword", formData.currentPassword) &&
      validateField("password", formData.password) &&
      validateField("confirmPassword", formData.confirmPassword)
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (validForm()) {
      dispatch(updateUserPassword(formData.currentPassword, formData.password));
    }
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    validateField(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (fieldName, value) => {
    let error,
      msg = "";
    switch (fieldName) {
      case "currentPassword":
        error = value.length >= 6;
        msg = error ? "" : "Merci de vérifier votre mot de passe";
        break;
      case "password":
        error = value.length >= 6;
        msg = error ? "" : "Le mot de passe doit être supérieur à 6 caractères";
        break;
      case "confirmPassword":
        error = value.length >= 6 && formData.password === value;
        msg = error
          ? ""
          : "Le mot de passe de confirmation doit être le même que le mot de passe";
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [fieldName]: msg });
    return error;
  };

  return (
    <div>
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="current_password" className="mt-4">
          

          <div className="label">Mot de passe actuel </div> 
          <Form.Control
            type="password"
            placeholder="Mot de passe actuel  "
            value={formData.currentPassword}
            name="currentPassword"
            onChange={(e) => handleUserInput(e)}
            className={formErrors["currentPassword"].length > 0 && "inputError"}
          ></Form.Control>
          {formErrors["currentPassword"].length > 0 && (
            <p className="alert alert-danger descError">
              {formErrors["currentPassword"]}
            </p>
          )}
        </Form.Group>
        <Form.Group controlId="password" className="mt-4">
          

          <div className="label">Mot de passe </div>
          <Form.Control
            type="password"
            placeholder="Mot de passe "
            value={formData.password}
            name="password"
            onChange={(e) => handleUserInput(e)}
            className={formErrors["password"].length > 0 && "inputError"}
          ></Form.Control>
          {formErrors["password"].length > 0 && (
            <p className="alert alert-danger descError">
              {formErrors["password"]}
            </p>
          )}
        </Form.Group>
        <Form.Group controlId="confirmPassword" className="mt-4">
         
          <div className="label">Confirmer Mot de passe  </div>
          <Form.Control
            type="password"
            placeholder="Confirmer Mot de passe "
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={(e) => handleUserInput(e)}
            className={formErrors["confirmPassword"].length > 0 && "inputError"}
          ></Form.Control>
          {formErrors["confirmPassword"].length > 0 && (
            <p className="alert alert-danger descError">
              {formErrors["confirmPassword"]}
            </p>
          )}
        </Form.Group>
        <Row className="py-3">
          <Col>
            <Button
              type="submit"
              style={{ marginTop: "20px", width: "auto" }}
              className="buttonn-design addbtn"
            >
              Modifier le mot de passe
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ChangePasswordScreen;
