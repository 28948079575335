export const WIFI_CREAT_REQUEST = "WIFI_CREAT_REQUEST";
export const WIFI_CREAT_SUCCESS = "WIFI_CREAT_SUCCESS";
export const WIFI_CREAT_FAIL = "WIFI_CREAT_FAIL";
export const WIFI_CREAT_RESET = "WIFI_CREAT_RESET";

export const WIFI_UPDATE_REQUEST = "WIFI_UPDATE_REQUEST";
export const WIFI_UPDATE_SUCCESS = "WIFI_UPDATE_SUCCESS";
export const WIFI_UPDATE_FAIL = "WIFI_UPDATE_FAIL";
export const WIFI_UPDATE_RESET = "WIFI_UPDATE_RESET";

export const WIFI_LIST_REQUEST = "WIFI_LIST_REQUEST";
export const WIFI_LIST_SUCCESS = "WIFI_LIST_SUCCESS";
export const WIFI_LIST_FAIL = "WIFI_LIST_FAIL";
export const WIFI_LIST_RESET = "WIFI_LIST_RESET";

export const WIFI_DELETE_REQUEST = "WIFI_DELETE_REQUEST";
export const WIFI_DELETE_SUCCESS = "WIFI_DELETE_SUCCESS";
export const WIFI_DELETE_FAIL = "WIFI_DELETE_FAIL";
export const WIFI_DELETE_RESET = "WIFI_DELETE_RESET";

export const WIFI_GET_REQUEST = "WIFI_GET_REQUEST";
export const WIFI_GET_SUCCESS = "WIFI_GET_SUCCESS";
export const WIFI_GET_FAIL = "WIFI_GET_FAIL";
export const WIFI_GET_RESET = "WIFI_GET_RESET";
