export const SOCIAL_MEDIA_CREAT_REQUEST = "SOCIAL_MEDIA_CREAT_REQUEST";
export const SOCIAL_MEDIA_CREAT_SUCCESS = "SOCIAL_MEDIA_CREAT_SUCCESS";
export const SOCIAL_MEDIA_CREAT_FAIL = "SOCIAL_MEDIA_CREAT_FAIL";
export const SOCIAL_MEDIA_CREAT_RESET = "SOCIAL_MEDIA_CREAT_RESET";

export const SOCIAL_MEDIA_GET_SOCIAL_MEDIA_REQUEST =
  "SOCIAL_MEDIA_GET_SOCIAL_MEDIA_REQUEST";
export const SOCIAL_MEDIA_GET_SOCIAL_MEDIA_SUCCESS =
  "SOCIAL_MEDIA_GET_SOCIAL_MEDIA_SUCCESS";
export const SOCIAL_MEDIA_GET_SOCIAL_MEDIA_FAIL =
  "SOCIAL_MEDIA_GET_SOCIAL_MEDIA_FAIL";

export const SOCIAL_MEDIA_UPDATE_REQUEST = "SOCIAL_MEDIA_UPDATE_REQUEST";
export const SOCIAL_MEDIA_UPDATE_SUCCESS = "SOCIAL_MEDIA_UPDATE_SUCCESS";
export const SOCIAL_MEDIA_UPDATE_FAIL = "SOCIAL_MEDIA_UPDATE_FAIL";
export const SOCIAL_MEDIA_UPDATE_RESET = "SOCIAL_MEDIA_UPDATE_RESET";

export const SOCIAL_MEDIA_LIST_REQUEST = "SOCIAL_MEDIA_LIST_REQUEST";
export const SOCIAL_MEDIA_LIST_SUCCESS = "SOCIAL_MEDIA_LIST_SUCCESS";
export const SOCIAL_MEDIA_LIST_FAIL = "SOCIAL_MEDIA_LIST_FAIL";
export const SOCIAL_MEDIA_LIST_RESET = "SOCIAL_MEDIA_LIST_RESET";

export const SOCIAL_MEDIA_DELETE_REQUEST = "SOCIAL_MEDIA_DELETE_REQUEST";
export const SOCIAL_MEDIA_DELETE_SUCCESS = "SOCIAL_MEDIA_DELETE_SUCCESS";
export const SOCIAL_MEDIA_DELETE_FAIL = "SOCIAL_MEDIA_DELETE_FAIL";
export const SOCIAL_MEDIA_DELETE_RESET = "SOCIAL_MEDIA_DELETE_RESET";
