import axios from "axios";
import {
  PUBLIC_CATEGORY_LIST_FAIL,
  PUBLIC_CATEGORY_LIST_REQUEST,
  PUBLIC_CATEGORY_LIST_SUCCESS,
  PUBLIC_DASH_FAIL,
  PUBLIC_DASH_REQUEST,
  PUBLIC_DASH_SUCCESS,
  PUBLIC_INFO_FAIL,
  PUBLIC_INFO_REQUEST,
  PUBLIC_INFO_SUCCESS,
  PUBLIC_SEARCH_FAIL,
  PUBLIC_SEARCH_REQUEST,
  PUBLIC_SEARCH_SUCCESS,
} from "../constants/publicConstants";
import {
  PUBLIC_CATEGORY_API,
  PUBLIC_DASH_API,
  PUBLIC_INFO_API,
  PUBLIC_SEARCH_API,
} from "../utils/apiConfig";

export const listPublicCategories = (menuId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PUBLIC_CATEGORY_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(PUBLIC_CATEGORY_API + menuId, config);

    dispatch({
      type: PUBLIC_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PUBLIC_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const visitorUserMenu = (userID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PUBLIC_DASH_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(PUBLIC_DASH_API + userID, config);

    dispatch({
      type: PUBLIC_DASH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PUBLIC_DASH_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const publicIndexInfo = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PUBLIC_INFO_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(PUBLIC_INFO_API, config);

    dispatch({
      type: PUBLIC_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PUBLIC_INFO_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const visitorSearchUser = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PUBLIC_SEARCH_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        name,
      },
    };

    const { data } = await axios.get(PUBLIC_SEARCH_API, config);

    dispatch({
      type: PUBLIC_SEARCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PUBLIC_SEARCH_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
