export const PRODUCT_CREAT_REQUEST = "PRODUCT_CREAT_REQUEST";
export const PRODUCT_CREAT_SUCCESS = "PRODUCT_CREAT_SUCCESS";
export const PRODUCT_CREAT_FAIL = "PRODUCT_CREAT_FAIL";
export const PRODUCT_CREAT_RESET = "PRODUCT_CREAT_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";
export const PRODUCT_LIST_RESET = "PRODUCT_LIST_RESET";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";
export const PRODUCT_DELETE_RESET = "PRODUCT_DELETE_RESET";
