import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { listMyImages } from "../../../actions/imageActions";
import {
  deleteSocialMedia,
  listMySocialMedias,
} from "../../../actions/socialMediaActions";
import AddSocialMedia from "../../../components/userSocialMedia/AddSocialMedia";
import { sweetBox } from "../../../utils/alert";

const UserSocialMediaScreen = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const socialMediaList = useSelector((state) => state.socialMediaListMy);
  const { socialMedias, error } = socialMediaList;

  useEffect(() => {
    dispatch(listMySocialMedias());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  const deleteSocialMediaNow = (id) => {
    sweetBox({
      icon: "warning",
      type: "warning",
      name: "supprimer",
      title: "Voulez-vous supprimer cette boutton ?",
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteSocialMedia(id));
    });
  };
  return (
    <main className="main-container">
      <div className="form-check mb-5">
        <h1 style={{ textAlign: "center" }}> les réseaux sociaux</h1>
      </div>

      <AddSocialMedia />

      <div className="mt-5" style={{ textAlign: "center" }}>
        {socialMedias &&
          socialMedias.map((sm, index) => (
            <div style={{ position: "relative" }} key={index}>
              <button
                className="buttonn-design"
                style={{ backgroundColor: sm.color, border: "none" }}
              >
                <a
                  href={sm.link}
                 
                  style={{ color: "white", textDecoration: "none" }}
                  target="_blank" rel="noopener noreferrer"
                >
                  <i className={sm.icon}></i> {sm.name} 
                </a>
                
              </button>
              <li>{sm.link} </li>

              <div
                style={{
                  position: "absolute",
                  color: "red",
                  fontSize: "25px",
                  top: "22px",
                  right: "45px",
                }}
                onClick={() => {
                  deleteSocialMediaNow(sm.id);
                }}
              >
                <i className="fas fa-times"></i>
              </div>
              <br />
            </div>
          ))}
      </div>
    </main>
  );
};

export default UserSocialMediaScreen;
