export const PAYMENT_CREAT_REQUEST = "PAYMENT_CREAT_REQUEST";
export const PAYMENT_CREAT_SUCCESS = "PAYMENT_CREAT_SUCCESS";
export const PAYMENT_CREAT_FAIL = "PAYMENT_CREAT_FAIL";
export const PAYMENT_CREAT_RESET = "PAYMENT_CREAT_RESET";

export const PAYMENT_UPDATE_REQUEST = "PAYMENT_UPDATE_REQUEST";
export const PAYMENT_UPDATE_SUCCESS = "PAYMENT_UPDATE_SUCCESS";
export const PAYMENT_UPDATE_FAIL = "PAYMENT_UPDATE_FAIL";
export const PAYMENT_UPDATE_RESET = "PAYMENT_UPDATE_RESET";

export const PAYMENT_LIST_REQUEST = "PAYMENT_LIST_REQUEST";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAIL = "PAYMENT_LIST_FAIL";
export const PAYMENT_LIST_RESET = "PAYMENT_LIST_RESET";

export const PAYMENT_DELETE_REQUEST = "PAYMENT_DELETE_REQUEST";
export const PAYMENT_DELETE_SUCCESS = "PAYMENT_DELETE_SUCCESS";
export const PAYMENT_DELETE_FAIL = "PAYMENT_DELETE_FAIL";
export const PAYMENT_DELETE_RESET = "PAYMENT_DELETE_RESET";

export const PAYMENT_GET_REQUEST = "PAYMENT_GET_REQUEST";
export const PAYMENT_GET_SUCCESS = "PAYMENT_GET_SUCCESS";
export const PAYMENT_GET_FAIL = "PAYMENT_GET_FAIL";
export const PAYMENT_GET_RESET = "PAYMENT_GET_RESET";
