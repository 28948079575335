import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listMyMenus } from "../../actions/menuActions";
import Loader from "../../components/Loader";

const DashboardUserScreen = () => {
  const dispatch = useDispatch();
  const menuList = useSelector((state) => state.menuListMy);
  const { menus, loading: loadingMenu } = menuList;
  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;
  useEffect(() => {
    dispatch(listMyMenus());
  }, [dispatch]);

  return (
    <div>
      <main className="main-container" style={{ textAlign: "center" }}>
        <h2 className="mt-5">Menu</h2>
        {loadingMenu ? (
          <Loader />
        ) : (
          menus &&
          menus.map((menu) => {
            if (userInfo.is_premium === menu.is_premium) {
              return (
                <>
                  <div key={menu.id}>
                    <Link to={"/user/menu/pro/" + menu.id}>
                      <button className="buttonn-design">Menu Pro</button>
                    </Link>
                    <br />
                  </div>
                </>
              );

              /*
            console.log(parseInt(menu.is_premium) === 0);
            return parseInt(menu.is_premium) === 0 ||
              userInfo.is_premium === menu.is_premium ? (
              <div key={menu.id}>
                <Link to={"/user/menu/" + menu.id}>
                  <button className="buttonn-design">{menu.name}</button>
                </Link>{" "}
                <br />
              </div>*/
            } else {
              return (
                <>
                  <div key={menu.id}>
                    <Link to={"/user/menu/" + menu.id}>
                      <button className="buttonn-design">Menu Classique</button>
                    </Link>
                    <br />
                  </div>
                </>
              );
            }
          })
        )}
        <br />
        <h2 className="mt-4">Wi-Fi</h2>
        <div className="mb-5 ">
          <Link to={"/user/wifi/" + 1}>
            <button className="buttonn-design">Wifi</button>
          </Link>
        </div>
        <h2>Réseaux Sociaux</h2>
        <div className="mb-4 ">
          <Link to="/user/list/socialMedia">
            <button className="buttonn-design">Modèle 1</button>
          </Link>
        </div>
        <div className="mb-5 ">
          <h2>Galerie</h2>
          <Link to={"/user/list/images"}>
            <button className="buttonn-design">Images</button>
          </Link>
        </div>

        <div className="mb-5 ">
          <h2>Promo/événements</h2>
          <Link to={"/user/discount"}>
            <button className="buttonn-design">Promo</button>
          </Link>
        </div>

        <div className="mb-5 ">
          <h2>Commandes et paiements</h2>
          <Link to={"/user/list/payment"}>
            <button className="buttonn-design">Paiement</button>
          </Link>
        </div>
      </main>
    </div>
  );
};

export default DashboardUserScreen;
