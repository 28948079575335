import Footer from "./components/Footer";
import Header from "./components/Header";
import Protected from "./components/Protected";

import Tutorial from "./screens/public/Tutorial";
import AdsPackageScreen from "./screens/public/Adspackage";
import AccountPackageScreen from "./screens/public/AccountPackage";
import ContactScreen from "./screens/public/Contact";
import HomeScreen from "./screens/public/HomeScreen";
import UserProfilScreen from "./screens/public/UserProfilScreen";
import MenuScreen from "./screens/public/MenuScreen";

import LoginScreen from "./screens/auth/LoginScreen";
import RegisterScreen from "./screens/auth/RegisterScreen";
import ResetPasswordScreen from "./screens/auth/ResetPasswordScreen";
import EditProfilScreen from "./screens/user/EditProfilScreen";
import UserMenuScreen from "./screens/user/UserMenuScreen";
import UserMenuProScreen from "./screens/user/UserMenuProScreen";
import DashboardUserScreen from "./screens/user/DashboardUserScreen";
import { Route, Routes } from "react-router-dom";
import QrcodeUser from "./screens/user/QrcodeUser";
import LoginWithTokenScreen from "./screens/auth/LoginWithTokenScreen";
import UsersList from "./screens/admin/usersList";
import UserWifiScreen from "./screens/user/wifi/UserWifiScreen";
import WifiScreen from "./screens/public/WifiScreen";
import { Helmet } from "react-helmet";
import UserImageScreen from "./screens/user/image/UserImageScreen";
import ImageScreen from "./screens/public/ImageScreen";
import StatistiqueScreen from "./screens/user/StatistiqueScreen";
import UserSocialMediaScreen from "./screens/user/socialMedia/UserSocialMediaScreen";
import SocialMediaScreen from "./screens/public/SocialMediaScreen";
import UserPaymentScreen from "./screens/user/payment/UserPaymentScreen";
import UserDiscountScreen from "./screens/user/discount/UserDiscountScreen";
import DiscountScreen from "./screens/public/DiscountScreen";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Qrcode.tn</title>
        <meta
          name="description"
          content="Le plus grand communauté de QR code dans le Tunisie"
        />
      </Helmet>
      <Header />

      <main>
        <Routes>
          <Route path="/tutorial" element={<ContactScreen />} exact />
          <Route path="/adspackage" element={<ContactScreen />} exact />
          <Route
            path="/accountpackage"
            element={<AccountPackageScreen />}
            exact
          />

          <Route path="/contact" element={<ContactScreen />} exact />
          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/:id" element={<UserProfilScreen />} exact />
          <Route path="/:id/menu" element={<MenuScreen />} exact />
          <Route path="/:id/wifi" element={<WifiScreen />} exact />
          <Route path="/:id/galerie" element={<ImageScreen />} exact />
          <Route path="/:id/discount" element={<DiscountScreen />} exact />
          <Route
            path="/:id/socialMedia"
            element={<SocialMediaScreen />}
            exact
          />

          <Route
            path="/login"
            element={
              <Protected nextPage={false}>
                <LoginScreen />
              </Protected>
            }
            exact
          />
          <Route
            path="/register"
            element={
              <Protected nextPage={false}>
                <RegisterScreen />
              </Protected>
            }
            exact
          />

          <Route
            path="/reset/password"
            element={
              <Protected nextPage={false}>
                <ResetPasswordScreen />
              </Protected>
            }
          />

          <Route
            path="/user/edit/profile"
            element={
              <Protected nextPage={true}>
                {" "}
                <EditProfilScreen />{" "}
              </Protected>
            }
          />

          <Route
            path="/user/dashboard"
            element={
              <Protected nextPage={true}>
                <DashboardUserScreen />
              </Protected>
            }
          />
          <Route
            path="/user/statistique"
            element={
              <Protected nextPage={true}>
                <StatistiqueScreen />
              </Protected>
            }
          />

          <Route
            path="/user/list/images"
            element={
              <Protected nextPage={true}>
                <UserImageScreen />
              </Protected>
            }
          />

          <Route
            path="/user/list/payment"
            element={
              <Protected nextPage={true}>
                <UserPaymentScreen />
              </Protected>
            }
          />

          <Route
            path="/user/discount"
            element={
              <Protected nextPage={true}>
                <UserDiscountScreen />
              </Protected>
            }
          />

          <Route
            path="/user/list/socialMedia"
            element={
              <Protected nextPage={true}>
                <UserSocialMediaScreen />
              </Protected>
            }
          />
          <Route
            path="/get/qrcode"
            element={
              <Protected nextPage={true}>
                <QrcodeUser />
              </Protected>
            }
          />

          <Route
            path="/user/menu/:id"
            element={
              <Protected nextPage={true}>
                <UserMenuScreen />
              </Protected>
            }
          />
          <Route
            path="/user/menu/pro/:id"
            element={
              <Protected nextPage={true}>
                <UserMenuProScreen />
              </Protected>
            }
          />

          <Route
            path="/user/wifi/:id"
            element={
              <Protected nextPage={true}>
                <UserWifiScreen />
              </Protected>
            }
          />

          <Route
            path="/login/token/:token"
            element={<LoginWithTokenScreen />}
          />

          <Route
            path="/admin/users"
            element={
              <Protected nextPage={true} role="admin">
                <UsersList />
              </Protected>
            }
          />
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;
