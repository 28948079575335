export const listDelivery = [
  {
    image: "/images/jumia.png",
    name: "Jumia Food",
    url: "https://food.jumia.com.tn/",
  },
  {
    image: "/images/yassir.png",
    name: "Yassir Express",
    url: "https://express.yassir.io/",
  },
  {
    image: "/images/glovo.png",
    name: "Glovo",
    url: "https://glovoapp.com/tn/en/",
  },
  {
    image: "/images/tiktak.png",
    name: "TikTak delivery",
    url: "https://www.tiktakdelivery.com/",
  },
];

export const listPayment = [
  {
    image: "/images/sodexo.png",
    name: "Sodexo",
  },
  {
    image: "/images/edinar.png",
    name: "Edinar",
  },
];
