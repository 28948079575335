import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { visitorUserMenu } from "../../actions/publicActions";
import Loader from "../../components/Loader";
import { listDelivery, listPayment } from "../../utils/payment";

const UserProfilScreen = () => {
  const params = useParams();
  const userID = params.id;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dataVisitor = useSelector((state) => state.publicVisitorUser);
  const { data, error, loading } = dataVisitor;

  useEffect(() => {
    dispatch(visitorUserMenu(userID));
  }, [dispatch, userID]);

  useEffect(() => {
    if (error) navigate("/");
  }, [error, navigate]);

  const verifIfExist = (array) => {
    let verif = false;
    if (data && data.user && data.user.features) {
      listPayment.map((payment, index) => {
        if (data.user.features.includes(payment.name)) {
          verif = true;
        }
      });
    }
    return verif;
  };
  return (
    <div style={{ alignItems: "center" }}>
      <Helmet>
        <title>{data && data.user && data.user.name}</title>
        <meta
          name="description"
          key="description"
          content={data && data.user && data.user.name}
        />
        <meta
          name="title"
          key="title"
          content={data && data.user && data.user.name}
        />
        <meta
          property="og:title"
          key="og:title"
          content={data && data.user && data.user.name}
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={data && data.user && data.user.name}
        />
        <meta
          property="og:image"
          key="og:image"
          content={
            data && data.user && data.user.image != null
              ? data.user.image
              : "/images/avatar.png"
          }
        />
      </Helmet>
      {!loading ? (
        <>
          {data && data.discount && data.discount.status == 1 && (
            <div className="discountnotif">
              <Link to={"/" + userID + "/discount"}>{data.discount.title}</Link>
            </div>
          )}
          <main className="container" style={{ textAlign: "center" }}>
            <img
              src={
                data && data.user && data.user.image != null
                  ? data.user.image
                  : "/images/avatar.png"
              }
              className="img-thumbnail round"
              style={{ width: "150px", height: "150px" }}
              alt="profile_image"
            />
            <div className="business-name">
              {data && data.user && data.user.name}
            </div>

            <div className="buttonns mt-2 mb-5">
              {data &&
                data.menus &&
                data.menus.map((menu) => (
                  <div key={menu.id}>
                    <Link to={"/" + userID + "/menu"}>
                      <button className="buttonn-design">Menu</button>
                    </Link>
                  </div>
                ))}

              {data &&
                data.wifi &&
                data.wifi.map((wifi) => (
                  <div key={wifi.id}>
                    <Link to={"/" + userID + "/wifi"}>
                      <button className="buttonn-design">Wi-Fi</button>
                    </Link>
                  </div>
                ))}

              {data && data.user && data.user.image_status == "1" && (
                <div>
                  <Link to={"/" + userID + "/galerie"}>
                    <button className="buttonn-design">Galerie</button>
                  </Link>
                </div>
              )}
              {data && data.user && data.user.socialmedia_status == "1" && (
                <Link to={"/" + userID + "/socialMedia"}>
                  <button className="buttonn-design"> Social Media</button>
                </Link>
              )}
            </div>

            <div>
              {verifIfExist(listDelivery) && (
                <div>
                  <div className="form-check mb-3">
                    <h6 style={{ textAlign: "center" }}>
                      Société de Livraison
                    </h6>
                  </div>
                  <div className="paymentMode paymentModeFromProfile ">
                    {listDelivery.map((payment, index) => {
                      if (
                        data &&
                        data.user &&
                        data.user.features &&
                        data.user.features.includes(payment.name)
                      )
                        return (
                          <div className="form-check" key={index * 3}>
                            <a href={payment.url} target="_blank" alt="">
                              <img src={payment.image} />
                            </a>
                          </div>
                        );
                    })}
                  </div>
                </div>
              )}
              {verifIfExist(listPayment) && (
                <div>
                  <div className="form-check mb-2  mt-3">
                    <h6 style={{ textAlign: "center" }}> Mode de payment </h6>
                  </div>
                  <div className="paymentMode paymentModeFromProfile mb-3">
                    {listPayment.map((payment, index) => {
                      if (
                        data &&
                        data.user &&
                        data.user.features &&
                        data.user.features.includes(payment.name)
                      )
                        return (
                          <div className="form-check" key={index * 3}>
                            <img src={payment.image} />
                          </div>
                        );
                    })}
                  </div>
                </div>
              )}

              {data && data.user && data.user.phone != null ? (
                <>
                  <div className="form-check mb-3">
                    <h6 style={{ textAlign: "center" }}>
                      Commandez maintenant
                    </h6>
                  </div>
                  <div style={{ textAlign: "center" }} className="mb-5">
                    <a
                      href={"tel:" + data.user.phone}
                      className="phoneUserProfile"
                    >
                      <i className="fas fa-phone-alt"></i>
                    </a>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </main>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default UserProfilScreen;
