import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../actions/userActions";
import Loader from "../../components/Loader";

const StatistiqueScreen = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { loading } = userUpdateProfile;

  useEffect(() => {
    dispatch(updateUserProfile({ phone: userInfo.phone, name: userInfo.name }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <section className="main-container">
        <h1 className="title">Statistique</h1>
        <div className="row col-sm-12">
          <div className="col-sm-4"></div>
          <div
            style={{
              textAlign: "center",
              marginTop: "30px",
            }}
            className="col-sm-4"
          >
            {loading ? (
              <Loader />
            ) : (
              <span style={{ fontSize: "50px", fontWeight: "600" }}>
                {userInfo?.nbrViews}
              </span>
            )}
            <h2>Visiteurs </h2>
          </div>

          <div className="col-sm-4"></div>
        </div>
      </section>
    </>
  );
};

export default StatistiqueScreen;
