import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../actions/userActions";
import Loader from "../Loader";
const EditProfil = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { loading } = userUpdateProfile;

  const [name, setName] = useState(userInfo && userInfo.name);
  const [phone, setPhone] = useState(userInfo && userInfo.phone);
  const [city, setCity] = useState(userInfo && userInfo.city);
  const [image, setImage] = useState("");
  const [loadings, setLoadingStatus] = useState(false);

  const editProfile = async () => {
    setLoadingStatus(true);
    try {
      const options = {
        maxSizeMB: 0.2,
      };
      let compressedFile = "";
      if (image != "") {
        compressedFile = await imageCompression(image, options);
        console.log(compressedFile.size / 1024);
      }
      const formData = new FormData();
      formData.append("image", compressedFile);
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("city", city);
      dispatch(updateUserProfile(formData));
    } catch (err) {
      console.log(err.message);
    }
    setLoadingStatus(false);
  };

  return (
    <div style={{ alignItems: "center" }}>
      <main className="container" style={{ textAlign: "center" }}>
        <img
          src={
            userInfo && userInfo.image != null
              ? userInfo.image
              : "/images/avatar.png"
          }
          className="img-thumbnail round"
          style={{ width: "150px", height: "150px" }}
          alt="profile_image"
        />

        <Form.Group controlId="image" className="mt-4">
          <div className="label">Photo de profile </div>

          <Form.Control
            type="file"
            placeholder="Image"
            onChange={(e) => setImage(e.target.files[0])}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="name" className="mt-4">
          <div className="label">Nom </div>

          <Form.Control
            type="text"
            placeholder="nom"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="phone" className="mt-4">
          <div className="label">Numéro de téléphone de votre compte </div>

          <Form.Control
            type="text"
            placeholder="Téléphone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="city" className="mt-4">
          <Form.Label>Ville</Form.Label>
          <Form.Select
            onChange={(e) => setCity(e.target.value)}
            name="city"
            aria-label="Default select example"
          >
            <option value="Default">Default</option>
            <option value="Tunis">Tunis</option>
            <option value="Sfax">Sfax</option>
            <option value="Béja">Béja</option>
            <option value="Gafsa">Gafsa</option>
            <option value="Sidi bouzid">Sidi bouzid</option>
            <option value="Gabés">Gabés</option>
            <option value="Gbeli">Gbeli</option>
            <option value="Mednine">Mednine</option>
            <option value="Tataouine">Tataouine</option>
            <option value="Gassrine">Gassrine</option>
            <option value="Mahdia">Mahdia</option>
            <option value="Kairouan">Kairouan</option>
            <option value="El kef">El kef</option>
            <option value="Sousse">Sousse</option>
            <option value="Bizerte">Bizerte</option>
            <option value="Nabeul">Nabeul</option>
            <option value="Siliana">Siliana</option>
            <option value="Jandouba">Jandouba</option>
            <option value="Tozeur">Tozeur</option>
            <option value="Monastir">Monastir</option>
            <option value="Zaghouan">Zaghouan</option>
          </Form.Select>
        </Form.Group>

        <div className="mt-5 ">
          <button
            className="buttonn-design addbtn"
            onClick={() => {
              editProfile();
            }}
            disabled={loading || loadings}
          >
            {loading || loadings ? <Loader /> : "Sauvegarder"}
          </button>
        </div>
      </main>
    </div>
  );
};

export default EditProfil;
