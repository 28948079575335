import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { visitorUserMenu } from "../../actions/publicActions";
import { getSocialMedia } from "../../actions/socialMediaActions";
import Loader from "../../components/Loader";

const SocialMediaScreen = () => {
  const params = useParams();
  const userID = params.id;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const socialMediaNow = useSelector((state) => state.getSocialMediaNow);
  const { socialMedias, error, loading } = socialMediaNow;

  useEffect(() => {
    dispatch(getSocialMedia(userID));
  }, [dispatch, userID]);

  useEffect(() => {
    if (error) navigate("/");
  }, [error, navigate]);

  return (
    <div style={{ alignItems: "center" }}>
      <Helmet>
        <title>Social Media</title>
      </Helmet>
      {!loading ? (
        <main className="container" style={{ textAlign: "center" }}>
          {socialMedias &&
            socialMedias.map((sm, index) => (
              <div className="buttonns mb-5" key={index}>
                <div>
                  <button
                    className="buttonn-design"
                    style={{ backgroundColor: sm.color, border: "none" }}
                  >
                    <a
                      href={sm.link}
                      style={{ color: "white", textDecoration: "none" }}
                      target="_blank"
                    >
                      <i className={sm.icon}></i> {sm.name}
                    </a>
                  </button>
                </div>
              </div>
            ))}
        </main>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SocialMediaScreen;
