import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCategory } from "../../actions/categoryActions";
import { toastAlert } from "../../utils/alert";
import Loader from "../Loader";
import imageCompression from "browser-image-compression";

const AddCategory = () => {
  const [category, setCategory] = useState({ name: "", image: null, id: "" });
  const [validForm, setValidForm] = useState(true);
  const [satusCategory, setsatusCategory] = useState(false);
  const [searched, setSearched] = useState(null);

  const createCat = useSelector((state) => state.categoryCreate);
  const {
    loading: loadingCat,
    // error: errorCat,
    success: successCat,
  } = createCat;

  const listCat = useSelector((state) => state.categoryListMy);
  const { categories } = listCat;

  const getMenuNow = useSelector((state) => state.getMenuNow);
  const { menu } = getMenuNow;

  const dispatch = useDispatch();

  useEffect(() => {
    if (successCat && satusCategory === true) {
      toastAlert("Votre catégorie a été ajouté");
      setCategory({ name: "", image: null, id: "" });
      setsatusCategory(false);
    }
  }, [successCat, satusCategory]);

  const addCategory = async () => {
    let searched1 = categories.find((o) => o.name === category);
    setSearched(searched1);

    if (category !== "" && !searched1) {
      let compressedFile = null;
      if (category.image) {
        const fileSize = category.image.size / 1024;
        if (fileSize < 5120) {
          try {
            const options = {
              maxSizeMB: 0.2,
            };
            compressedFile = await imageCompression(category.image, options);
            console.log(compressedFile.size / 1024);
          } catch (err) {
            console.log(err.message);
          }
        } else {
          toastAlert("L'image doit être de taille inférieure 5Mo", "error");
        }
      }
      const formData = new FormData();
      formData.append("image", compressedFile);
      formData.append("name", category.name);
      dispatch(createCategory(formData));
      setsatusCategory(true);
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  };

  return (
    <>
      <div className="label"> Ajouter Catégorie </div>

      {menu && menu.is_premium == 1 && (
        <div className="mb-3 mt-4">
          <input
            type="file"
            className={"form-control "}
            placeholder="Image Catégorie"
            onChange={(e) => {
              setCategory({ ...category, image: e.target.files[0] });
              setSearched(categories.find((o) => o.name === e.target.value));
            }}
          />
        </div>
      )}
      <div className="mb-3 mt-4">
        <input
          type="text"
          className={
            "form-control " +
            ((category.length === 0 && !validForm) ||
            (!validForm && searched != null)
              ? "inputError"
              : "")
          }
          id="exampleFormControlInput1"
          placeholder="Catégorie: pizza"
          value={category.name}
          onChange={(e) => {
            setCategory({ ...category, name: e.target.value });
            setSearched(categories.find((o) => o.name === e.target.value));
          }}
        />
        {((category.length === 0 && !validForm) ||
          (!validForm && searched != null)) && (
          <p className="alert alert-danger descError">
            La catégorie est obligatoire
          </p>
        )}
      </div>
      <button
        className="light-btn"
        onClick={() => {
          addCategory();
        }}
        disabled={loadingCat}
      >
        {loadingCat ? <Loader /> : "Ajouter"}
      </button>
    </>
  );
};

export default AddCategory;
