import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, editCategory } from "../../actions/categoryActions";
import { deleteProduct, editProduct } from "../../actions/productActions";
import { sweetBox, toastAlert } from "../../utils/alert";
import Loader from "../Loader";

const ListCatProductUser = () => {
  const dispatch = useDispatch();

  const [category, setCategory] = useState({ name: "", id: "" });
  const [product, setProduct] = useState({
    name: "",
    description: "",
    id: "",
    price: "",
  });

  const [searched, setSearched] = useState(null);
  const [validForm, setValidForm] = useState(true);
  const [satusCategory, setsatusCategory] = useState(false);
  const [satusProduct, setsatusProduct] = useState(false);

  const listCat = useSelector((state) => state.categoryListMy);
  const { categories } = listCat;

  const editCat = useSelector((state) => state.categoryUpdate);
  const {
    loading: loadingCat,
    // error: errorCat,
    success: successCat,
  } = editCat;

  const editProd = useSelector((state) => state.productUpdate);
  const {
    loading: loadingProd,
    // error: errorCat,
    success: successProd,
  } = editProd;

  const getMenuNow = useSelector((state) => state.getMenuNow);
  const { menu } = getMenuNow;

  const deleteProductA = (product) => {
    sweetBox({
      icon: "warning",
      type: "warning",
      name: "supprimer",
      title: "Voulez-vous supprimer ce produit ?",
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteProduct(product));
    });
  };

  const deleteCategoryA = (category) => {
    sweetBox({
      icon: "warning",
      type: "warning",
      name: "supprimer",
      title: "Voulez-vous supprimer cette catégorie ?",
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteCategory(category));
    });
  };

  const editCategoryNow = () => {
    let searched1 = categories.find((o) => o.name === category.name);
    setSearched(searched1);
    console.log(category !== "" && !searched1);
    if (category !== "" && !searched1) {
      setsatusCategory(true);
      console.log(category);
      dispatch(editCategory(category));
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  };

  const editProductNow = () => {
    if (product.name.length > 0 && product.price > 0) {
      dispatch(editProduct(product));
      setsatusProduct(true);
    }
  };

  useEffect(() => {
    if (successCat && satusCategory === true) {
      toastAlert("Votre categorie a été modifiée");
      setCategory("");
      setsatusCategory(false);
      document.getElementById("CloseEditForm").click();
    }
  }, [successCat, satusCategory]);

  useEffect(() => {
    if (successProd && satusProduct === true) {
      toastAlert("Votre produit a été modifiée");
      document.getElementById("CloseEditProduct").click();
    }
  }, [successProd, satusProduct]);

  return (
    <>
      {categories &&
        categories.map((cat) => (
          <div key={cat.id * 2}>
            <button
              className="btn btn-primary mt-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#collapseone" + cat.id}
              aria-expanded="false"
              style={{
                backgroundColor:
                  menu && menu.category_color ? menu.category_color : "none",
              }}
            >
              <div>
                <i
                  className="fas fa-times-circle"
                  onClick={() => {
                    deleteCategoryA(cat);
                  }}
                ></i>{" "}
                <i
                  className="fas fa-edit"
                  data-bs-toggle="modal"
                  data-bs-target="#editCategory"
                  onClick={() => {
                    setCategory({ id: cat.id, name: cat.name });
                  }}
                ></i>
                {cat.image && (
                  <img
                    style={{
                      maxWidth: "50px",
                      maxHeight: "50px",
                      marginLeft: "20px",
                    }}
                    src={cat.image}
                  />
                )}
              </div>
              {cat.name}
              <i className="fas fa-caret-down"></i>
            </button>

            <div
              className={"collapse " + (menu && menu.food_color)}
              id={"collapseone" + cat.id}
            >
              <div className="card card-body">
                {cat.products &&
                  cat.products.map((product) => (
                    <div key={product.id * 2}>
                      <div className="horizontal-position">
                        <div className="border-box-1">
                          <nobr>{product.name}</nobr>
                        </div>

                        <div className="border-box-1">{product.price}DT</div>
                        <div>
                          <i
                            className="fas fa-times-circle"
                            onClick={() => {
                              deleteProductA(product);
                            }}
                          ></i>
                          <i
                            className="fas fa-edit"
                            data-bs-toggle="modal"
                            data-bs-target="#editProduct"
                            onClick={() => {
                              setProduct(product);
                            }}
                          ></i>
                        </div>
                      </div>
                      <div className=" mt-4 ">
                        <p className="p-4 product-description ">
                          {product.image && (
                            <img
                              style={{
                                maxWidth: "50px",
                                maxHeight: "50px",
                                marginLeft: "20px",
                              }}
                              src={product.image}
                            />
                          )}
                          {product.description}
                        </p>
                      </div>
                      <hr style={{ color: "grey" }} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}

      <div
        className="modal fade"
        id="editCategory"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="editCategoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editCategoryLabel">
                Modifier Categorie
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className={
                  "form-control " +
                  ((category?.name?.length === 0 && !validForm) ||
                  (!validForm && searched != null)
                    ? "inputError"
                    : "")
                }
                id="exampleFormControlInput1"
                placeholder="Catégorie: pizza"
                value={category?.name}
                onChange={(e) => {
                  setCategory({ ...category, name: e.target.value });
                  setSearched(
                    categories.find((o) => o.name === e.target.value)
                  );
                }}
              />
              {((category?.name?.length === 0 && !validForm) ||
                (!validForm && searched != null)) && (
                <p className="alert alert-danger descError">
                  Le Catégorie est obligatoire
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="light-btn"
                data-bs-dismiss="modal"
                id="CloseEditForm"
                style={{ backgroundColor: "red", border: "none" }}
              >
                Close
              </button>
              <button
                type="button"
                className=" light-btn addbtn"
                disabled={loadingCat}
                onClick={() => {
                  editCategoryNow();
                }}
              >
                {loadingCat ? <Loader /> : "Sauvegarder"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editProduct"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="editProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editProductLabel">
                Modifier Produit
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className=" input-group mt-3">
                <input
                  type="text"
                  className={
                    "form-control " +
                    (product?.name?.length === 0 ? "inputError" : "")
                  }
                  id="exampleFormControlInput1"
                  placeholder="Produit: pizza"
                  value={product?.name}
                  onChange={(e) => {
                    setProduct({ ...product, name: e.target.value });
                  }}
                />
                {product?.name?.length === 0 && (
                  <p className="alert alert-danger descError">
                    Nom de la produit est obligatoire
                  </p>
                )}
              </div>

              <div className=" input-group mt-3">
                <input
                  type="text"
                  className="form-control "
                  id="exampleFormControlInput1"
                  placeholder="Produit: pizza"
                  value={product?.description}
                  onChange={(e) => {
                    setProduct({ ...product, description: e.target.value });
                  }}
                />
              </div>
              <div className=" input-group mt-3">
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Prix : ' 12 '"
                  className={
                    "form-control " +
                    (product?.price?.toString().length === 0
                      ? "inputError"
                      : "")
                  }
                  value={product.price}
                  onChange={(e) => {
                    setProduct({ ...product, price: e.target.value });
                  }}
                />
                {product?.price?.toString().length === 0 && (
                  <p className="alert alert-danger descError">
                    Prix de la produit est obligatoire
                  </p>
                )}
                <div className="input-group-append">
                  <label
                    className="input-group-text"
                    style={{ borderRadius: "0 5px 5px 0" }}
                  >
                    DT
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="light-btn"
                data-bs-dismiss="modal"
                id="CloseEditProduct"
                style={{ backgroundColor: "red", border: "none" }}
              >
                Close
              </button>
              <button
                type="button"
                className=" light-btn addbtn"
                disabled={loadingProd}
                onClick={() => {
                  editProductNow();
                }}
              >
                {loadingProd ? <Loader /> : "Sauvegarder"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListCatProductUser;
