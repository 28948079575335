import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, editCategory } from "../../actions/categoryActions";
import { deleteProduct, editProduct } from "../../actions/productActions";
import { sweetBox, toastAlert } from "../../utils/alert";
import Loader from "../Loader";
import imageCompression from "browser-image-compression";

const ListPremiumCatProductUser = () => {
  const dispatch = useDispatch();

  const [category, setCategory] = useState({ name: "", id: "", image: null });
  const [product, setProduct] = useState({
    name: "",
    image: null,
    description: "",
    id: "",
    price: "",
  });

  const [searched, setSearched] = useState(null);
  const [validForm, setValidForm] = useState(true);
  const [satusCategory, setsatusCategory] = useState(false);
  const [satusProduct, setsatusProduct] = useState(false);
  const [loadingAddProd, setLoadingAddProd] = useState(false);
  const [loadingAddCat, setloadingAddCat] = useState(false);

  const listCat = useSelector((state) => state.categoryListMy);
  const { categories } = listCat;

  const editCat = useSelector((state) => state.categoryUpdate);
  const {
    loading: loadingCat,
    // error: errorCat,
    success: successCat,
  } = editCat;

  const editProd = useSelector((state) => state.productUpdate);
  const {
    loading: loadingProd,
    // error: errorCat,
    success: successProd,
  } = editProd;

  const getMenuNow = useSelector((state) => state.getMenuNow);
  const { menu } = getMenuNow;

  const deleteProductA = (product) => {
    sweetBox({
      icon: "warning",
      type: "warning",
      name: "supprimer",
      title: "Voulez-vous supprimer ce produit ?",
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteProduct(product));
    });
  };

  const deleteCategoryA = (category) => {
    sweetBox({
      icon: "warning",
      type: "warning",
      name: "supprimer",
      title: "Voulez-vous supprimer cette catégorie ?",
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteCategory(category));
    });
  };

  const editCategoryNow = async () => {
    let searched1 = categories.find((o) => o.name === category);
    setSearched(searched1);
    console.log(category !== "" && !searched1);
    if (category !== "" && !searched1) {
      setsatusCategory(true);

      let compressedFile = null;
      setloadingAddCat(true);
      if (category.image) {
        const fileSize = category.image.size / 1024;
        if (fileSize < 5120) {
          try {
            const options = {
              maxSizeMB: 0.2,
            };
            compressedFile = await imageCompression(category.image, options);
            console.log(compressedFile.size / 1024);
          } catch (err) {
            console.log(err.message);
          }
        } else {
          toastAlert("L'image doit être de taille inférieure 5Mo", "error");
        }
      }

      setloadingAddCat(false);

      const formData = new FormData();
      formData.append("image", compressedFile);
      formData.append("name", category.name);
      formData.append("id", category.id);

      dispatch(editCategory(formData));
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  };

  const editProductNow = async () => {
    if (product.name.length > 0 && product.price > 0) {
      setLoadingAddProd(true);
      let compressedFile = null;

      if (product.image) {
        const fileSize = product.image.size / 1024;
        if (fileSize < 5120) {
          try {
            const options = {
              maxSizeMB: 0.2,
            };
            compressedFile = await imageCompression(product.image, options);
            console.log(compressedFile.size / 1024);
          } catch (err) {
            console.log(err.message);
          }
        } else {
          toastAlert("L'image doit être de taille inférieure 5Mo", "error");
        }
      }
      setLoadingAddProd(false);
      const formDatas = new FormData();
      formDatas.append("image", compressedFile);
      formDatas.append("name", product.name);
      formDatas.append("desc", product.desc);
      formDatas.append("price", product.price);
      formDatas.append("id", product.id);

      dispatch(editProduct(formDatas));
      setsatusProduct(true);
    }
  };

  useEffect(() => {
    if (successCat && satusCategory === true) {
      toastAlert("Votre categorie a été modifiée");
      setCategory("");
      setsatusCategory(false);
      document.getElementById("CloseEditForm").click();
    }
  }, [successCat, satusCategory]);

  useEffect(() => {
    if (successProd && satusProduct === true) {
      toastAlert("Votre produit a été modifiée");
      document.getElementById("CloseEditProduct").click();
    }
  }, [successProd, satusProduct]);

  return (
    <>
      {categories &&
        categories.map((cat) => (
          <div key={cat.id * 2}>
            <button
              className="btn btn-primary mt-5"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#collapseone" + cat.id}
              aria-expanded="false"
              style={{
                backgroundColor:
                  menu && menu.category_color ? menu.category_color : "none",
              }}
            >
              <div>
                <i
                  className="fas fa-times-circle"
                  onClick={() => {
                    deleteCategoryA(cat);
                  }}
                ></i>{" "}
                <i
                  className="fas fa-edit"
                  data-bs-toggle="modal"
                  data-bs-target="#editCategory"
                  onClick={() => {
                    setCategory(cat);
                  }}
                ></i>
                {cat.image && (
                  <img
                    style={{
                      maxWidth: "50px",
                      maxHeight: "50px",
                      marginLeft: "20px",
                    }}
                    src={cat.image}
                  />
                )}
              </div>
              {cat.name}
              <i className="fas fa-caret-down"></i>
            </button>

            <div
              className={"collapse " + (menu && menu.food_color)}
              id={"collapseone" + cat.id}
            >
              <div className="card card-body">
                {cat.products &&
                  cat.products.map((product) => (
                    <div key={product.id * 2}>
                      <div className="horizontal-position">
                        <div className="border-box-1">
                          <nobr>{product.name}</nobr>
                        </div>

                        <div className="border-box-1">{product.price}DT</div>
                        <div>
                          <i
                            className="fas fa-times-circle"
                            onClick={() => {
                              deleteProductA(product);
                            }}
                          ></i>
                          <i
                            className="fas fa-edit"
                            data-bs-toggle="modal"
                            data-bs-target="#editProduct"
                            onClick={() => {
                              setProduct(product);
                            }}
                          ></i>
                        </div>
                      </div>
                      <div className=" mt-4 ">
                        <p className="p-4 product-description ">
                          {product.image && (
                            <img
                              style={{
                                maxWidth: "50px",
                                maxHeight: "50px",
                                marginLeft: "20px",
                              }}
                              src={product.image}
                            />
                          )}
                          {product.description}
                        </p>
                      </div>
                      <hr style={{ color: "grey" }} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}

      <div
        className="modal fade"
        id="editCategory"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="editCategoryLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editCategoryLabel">
                Modifier Categorie
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3 mt-4">
                <input
                  type="file"
                  className={"form-control "}
                  placeholder="Image Catégorie"
                  onChange={(e) => {
                    setCategory({ ...category, image: e.target.files[0] });
                  }}
                />
              </div>

              <input
                type="text"
                className={
                  "form-control " +
                  ((category?.name?.length === 0 && !validForm) ||
                  (!validForm && searched != null)
                    ? "inputError"
                    : "")
                }
                id="exampleFormControlInput1"
                placeholder="Catégorie: pizza"
                value={category?.name}
                onChange={(e) => {
                  setCategory({ ...category, name: e.target.value });
                  setSearched(
                    categories.find((o) => o.name === e.target.value)
                  );
                }}
              />
              {((category?.name?.length === 0 && !validForm) ||
                (!validForm && searched != null)) && (
                <p className="alert alert-danger descError">
                  Le Catégorie est obligatoire
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="light-btn"
                data-bs-dismiss="modal"
                id="CloseEditForm"
                style={{ backgroundColor: "red", border: "none" }}
              >
                Close
              </button>
              <button
                type="button"
                className=" light-btn addbtn"
                disabled={loadingCat}
                onClick={() => {
                  editCategoryNow();
                }}
              >
                {loadingCat || loadingAddCat ? <Loader /> : "Sauvegarder"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editProduct"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="editProductLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editProductLabel">
                Modifier Produit
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className=" input-group mt-3">
                <input
                  type="file"
                  className={"form-control "}
                  placeholder="Image Produit"
                  onChange={(e) =>
                    setProduct({ ...product, image: e.target.files[0] })
                  }
                />
              </div>
              <div className=" input-group mt-3">
                <input
                  type="text"
                  className={
                    "form-control " +
                    (product?.name?.length === 0 ? "inputError" : "")
                  }
                  id="exampleFormControlInput1"
                  placeholder="Produit: pizza"
                  value={product?.name}
                  onChange={(e) => {
                    setProduct({ ...product, name: e.target.value });
                  }}
                />
              </div>
              {product?.name?.length === 0 && (
                <p className="alert alert-danger descError">
                  Nom de la produit est obligatoire
                </p>
              )}
              <div className=" input-group mt-3">
                <input
                  type="text"
                  className="form-control "
                  id="exampleFormControlInput1"
                  placeholder="Description: pizza"
                  value={product?.description}
                  onChange={(e) => {
                    setProduct({ ...product, description: e.target.value });
                  }}
                />
              </div>
              <div className=" input-group mt-3">
                <input
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Prix : ' 12 '"
                  className={
                    "form-control " +
                    (product?.price?.toString().length === 0
                      ? "inputError"
                      : "")
                  }
                  value={product.price}
                  onChange={(e) => {
                    setProduct({ ...product, price: e.target.value });
                  }}
                />

                <div className="input-group-append">
                  <label
                    className="input-group-text"
                    style={{ borderRadius: "0 5px 5px 0" }}
                  >
                    DT
                  </label>
                </div>
              </div>
              {product?.price?.toString().length === 0 && (
                <p className="alert alert-danger descError">
                  Prix de la produit est obligatoire
                </p>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="light-btn"
                data-bs-dismiss="modal"
                id="CloseEditProduct"
                style={{ backgroundColor: "red", border: "none" }}
              >
                Close
              </button>
              <button
                type="button"
                className=" light-btn addbtn"
                disabled={loadingProd}
                onClick={() => {
                  editProductNow();
                }}
              >
                {loadingProd || loadingAddProd ? <Loader /> : "Sauvegarder"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListPremiumCatProductUser;
