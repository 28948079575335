export const listIcons = [
  {
    icon: "fab fa-github",
    color: "black",
    name: "Github",
  },
  {
    icon: "fab fa-facebook",
    color: "#1877F2",
    name: "Facebook",
  },
  {
    icon: "fab fa-instagram",
    color: "#E8486B",
    name: "Instagram",
  },
  {
    icon: "fab fa-linkedin",
    color: "#0077B7",
    name: "Linkedin",
  },
  {
    icon: "fab fa-twitter",
    color: "#00A2F4",
    name: "Twitter",
  },
  {
    icon: "fab fa-youtube",
    color: "#fe0000",
    name: "YouTube",
  },
  {
    icon: "fas fa-globe-americas",
    color: "black",
    name: "Site Web",
  },
  {
    icon: "fas fa-map-marker-alt",
    color: "#32A350",
    name: "localisation",
  },

  {
    icon: "fab fa-tiktok",
    color: "black",
    name: "tiktok",
  },

  {
    icon: "fa fa-envelope",
    color: "#fd7e34",
    name: "email",
  },

  {
    icon: "fab fa-whatsapp ",
    color: "green",
    name: "whatsapp",
  },

];

 