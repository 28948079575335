import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createPayment, listMypayment } from "../../../actions/paymentActions";
import Loader from "../../../components/Loader";
import { toastAlert } from "../../../utils/alert";
import { listDelivery, listPayment } from "../../../utils/payment";

const UserPaymentScreen = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const paymentCreate = useSelector((state) => state.paymentCreate);
  const { loading, success } = paymentCreate;

  const listPayments = useSelector((state) => state.paymentList);
  const { success: successList, payments } = listPayments;

  const [successS, setSuccessS] = useState(true);
  const [paymentSelected, setPaymentSelected] = useState("");

  useEffect(() => {
    dispatch(listMypayment());
  }, [dispatch]);

  useEffect(() => {
    if (successList) {
      setPaymentSelected(payments.features != null ? payments.features : "");
      document.getElementById("phone").value = payments.phone;
    }
  }, [payments, successList]);

  useEffect(() => {
    if (success && successS) {
      toastAlert("Votre modification a été modifié");

      setSuccessS(false);
    }
  }, [success, successS]);

  const saveChanges = () => {
    const elems = document.getElementsByName("payment");
    let result = "";
    elems.forEach((element) => {
      if (element.checked) {
        if (result.length != 0) result += ",";
        result += element.value;
      }
    });
    setSuccessS(true);
    dispatch(
      createPayment({
        features: result,
        phone: document.getElementById("phone").value,
      })
    );
  };
  const paymentChange = (value) => {
    if (paymentSelected.includes(value)) {
      setPaymentSelected(paymentSelected.replace(value, ""));
    } else {
      const result = paymentSelected + value;
      setPaymentSelected(result);
    }
  };
  return (
    <main className="main-container">
      {successList ? (
        <>
          <div className="form-check mb-5 mt-3">
            <h2 style={{ textAlign: "center" }}> Société de Livraison </h2>
          </div>
          <div className="paymentMode">
            {listDelivery.map((payment, index) => {
              return (
                <div className="form-check" key={index * 2}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={payment.name}
                    name="payment"
                    checked={paymentSelected.includes(payment.name)}
                    onChange={(e) => {
                      paymentChange(e.target.value);
                    }}

                    style={{
                      height: "30px",
                      width: "70px !important",
                    }}
                  />
                  <img src={payment.image} />
                  <label className="form-check-label" for="flexCheckDefault">
                    {payment.name}
                  </label>
                </div>
              );
            })}
          </div>

          <div className="form-check mb-5  mt-5">
            <h2 style={{ textAlign: "center" }}> Mode de paiement </h2>
          </div>
          <div className="paymentMode">
            {listPayment.map((payment, index) => {
              return (
                <div className="form-check" key={index * 3}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={payment.name}
                    name="payment"
                    checked={paymentSelected.includes(payment.name)}
                    onChange={(e) => {
                      paymentChange(e.target.value);
                    }}
                  />
                  <img src={payment.image} />
                  <label className="form-check-label" for="flexCheckDefault">
                    {payment.name}
                  </label>
                </div>
              );
            })}
          </div>
          <div>
            <div className="form-check mb-5  mt-5">
              <h2 className="text-center"> Numéro de téléphone </h2>
            </div>
            <div className="mb-3 mt-4 text-center ">
             <span style={{ backgroundColor: "#00c506" , padding: "6px" , borderRadius: "50%" , margin: "3px" }}> <i className="fa fa-phone "></i> </span>
              <input
                type="text"
                className={"form-control "}
                placeholder="votre numéro de téléphone"
                id="phone"
                style={{
                  width: "270px",
                  display: "inline",
                }}
                onChange={(e) => {
                  // setCategory(e.target.value);
                  // setSearched(categories.find((o) => o.name === e.target.value));
                }}
              />
            </div>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <button
                className="light-btn"
                onClick={() => {
                  saveChanges();
                }}
                disabled={loading}
              >
                {loading ? <Loader /> : "Sauvgarder"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </main>
  );
};

export default UserPaymentScreen;
