import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader";
import { getDiscount } from "../../actions/discountActions";

const DiscountScreen = () => {
  let navigate = useNavigate();

  const params = useParams();
  const discountId = params.id;

  const dispatch = useDispatch();

  const getDis = useSelector((state) => state.getDiscount);
  const { loading, error, discount } = getDis;

  useEffect(() => {
    dispatch(getDiscount(discountId));
  }, [dispatch, discountId, getDiscount]);

  useEffect(() => {
    console.log(error);
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  return (
    <main
      style={{
        minHeight: "100vh",
        backgroundColor: "#ffcd1b",
      }}
      className="main-container"
    >
      <Helmet>
        <title>Promo</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="form-check col-sm-12 text-center pt-5">
            <img
              style={{
                width: "90%",
              }}
              src={discount && discount.image}
            />
          </div>
          <div className="text-center p-4 pt-5">
            <h2 className="mt-1 font-weight-bold">
              {discount && discount.title}
            </h2>
            <p className="mt-2">{discount && discount.description}</p>
          </div>

          {discount && discount.url && (
            <div className="text-center mt-2">
              <a target="_blank" href={discount.url}>
                <button className="buttonn-design"> Voir plus </button>
              </a>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default DiscountScreen;
