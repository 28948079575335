import React from "react";

const Footer = () => {
  return (
    <>
     <div style={{ width:"100%", display: "none"}}> <img style={{ width:"100%"}} src="/images/paid/ad.png"></img></div>
    <div style={{ fontSize : "12px"}} className="bg-dark text-white text-center p-3 ">
    <span >QrCode.tn</span> © Tous les droits
        sont réservés 2022
      </div>
    </>
  );
};

export default Footer;
