import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getWifi } from "../../../actions/wifiActions";
import AddUserWifi from "../../../components/UserWifi/AddUserWifi";

const UserWifiScreen = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const getWifiNow = useSelector((state) => state.getWifiNow);
  const { error } = getWifiNow;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(getWifi(userInfo.slug));
  }, [dispatch, userInfo.slug]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error, navigate]);

  return (
    <main className="main-container">
      <div className="form-check mb-5 mt-3">
        <h1 style={{ textAlign: "center" }}> Ajouter Wi-Fi</h1>
      </div>

      <AddUserWifi />
    </main>
  );
};

export default UserWifiScreen;
