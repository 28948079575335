import {
  CHANGE_PREMIUM_STATUS_FAIL,
  CHANGE_PREMIUM_STATUS_REQUEST,
  CHANGE_PREMIUM_STATUS_RESET,
  CHANGE_PREMIUM_STATUS_SUCCESS,
} from "../constants/premiumConstants";

export const chngePremiumStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PREMIUM_STATUS_REQUEST:
      return {
        loading: true,
      };

    case CHANGE_PREMIUM_STATUS_SUCCESS:
      return {
        loading: false,
        success: true,
        discount: action.payload,
      };
    case CHANGE_PREMIUM_STATUS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CHANGE_PREMIUM_STATUS_RESET:
      return {};

    default:
      return state;
  }
};
