import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createImage, editImage } from "../../actions/imageActions";
import { IMAGE_UPDATE_RESET } from "../../constants/imageConstants";
import { toastAlert } from "../../utils/alert";
import Loader from "../Loader";

const AddUserImage = () => {
  const dispatch = useDispatch();

  const imageAdd = useSelector((state) => state.imageCreate);
  const statuschanged = useSelector((state) => state.imageUpdate);
  const user = useSelector((state) => state.userLogin);

  const listMyImage = useSelector((state) => state.imageListMy);
  const { images } = listMyImage;

  const { userInfo } = user;

  const { loading, success } = imageAdd;
  const { loading: loadingStatus, success: successStatus } = statuschanged;

  const [status, setStatus] = useState(false);

  const [formData, setFormData] = useState({
    image: "",
  });

  const [loadings, setLoadingStatus] = useState(false);

  const [formErrors, setFormErrors] = useState({
    image: "",
  });

  useEffect(() => {
    if (success) {
      toastAlert("Votre Galerie a été ajoutée");
    }
  }, [success]);

  useEffect(() => {
    if (successStatus) {
      toastAlert("Votre Status a été modifiée");
      dispatch({ type: IMAGE_UPDATE_RESET });
    }
  }, [dispatch, successStatus]);

  const validForm = () => {
    return validateField("image", formData.image);
  };

  useEffect(() => {
    setStatus(userInfo.image_status == "1" ? true : false);
  }, [userInfo.image_status]);

  const ajouterImageNow = async () => {
    if (validForm()) {
      if (
        images.length < 4 ||
        (images.length < 31 && userInfo.is_premuim == 1)
      ) {
        const fileSize = formData.image.size / 1024;
        if (fileSize < 5120) {
          setLoadingStatus(true);
          try {
            const options = {
              maxSizeMB: 0.2,
            };
            const compressedFile = await imageCompression(
              formData.image,
              options
            );

            console.log(compressedFile.size / 1024);
            const formDatas = new FormData();
            formDatas.append("image", compressedFile);
            dispatch(createImage(formDatas));
          } catch (err) {
            console.log(err.message);
          }
          setLoadingStatus(false);
        } else {
          toastAlert("L'image doit être de taille inférieure 5Mo", "error");
        }
      } else {
        toastAlert("Le nombre de l'image doit être inférieure a 4", "error");
      }
    }
  };

  const handleUserInput = (e) => {
    const value = e.target.files[0];

    validateField("image", value);
    setFormData({ image: value });
  };

  const validateField = (fieldName, value) => {
    let error,
      msg = "";
    switch (fieldName) {
      case "image":
        error = value != null;
        msg = error ? "" : "L'image est obligatoire";
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [fieldName]: msg });
    return error;
  };

  const changeStatus = () => {
    setStatus(!status);
  };

  const sendToServer = () => {
    dispatch(
      editImage({
        id: "1",
        status: status ? "1" : "0",
      })
    );
  };
  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <div className="label">Activer La Galerie</div>
        </Col>

        <Col>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              style={{
                height: "30px",
                width: "70px !important",
              }}
              checked={status}
              onChange={() => {
                changeStatus();
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="mb-3">
        <input
          type="file"
          accept="images/*"
          id="exampleFormControlInput1"
          name="image"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["image"].length > 0 ? "inputError" : "")
          }
        />
        {formErrors["image"].length > 0 && (
          <p className="alert alert-danger descError">{formErrors["image"]}</p>
        )}
      </div>

      <button
        className="light-btn"
        onClick={() => {
          ajouterImageNow();
        }}
        disabled={loading || loadings}
      >
        {loading || loadings ? <Loader /> : "Ajouter"}
      </button>

      <div style={{ textAlign: "right" }}>
        <button
          className="light-btn addbtn"
          onClick={() => {
            sendToServer();
          }}
          disabled={loadingStatus}
        >
          {loadingStatus ? <Loader /> : "Sauvegarder"}
        </button>
      </div>
    </>
  );
};

export default AddUserImage;
