import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import {
  productCreatReducer,
  productDeleteReducer,
  productListMyReducer,
  productUpdateReducer,
} from "./reducers/productReducers";
import {
  userChangePasswordReducer,
  userLoginReducer,
  userProfileDetailsReducer,
  userResetPasswordReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
  getAllUsersReducer,
} from "./reducers/userReducers";
import {
  categoryCreatReducer,
  categoryDeleteReducer,
  categoryUpdateReducer,
  categoryListMyReducer,
  restoreCreatReducer,
} from "./reducers/categoryReducers";

import {
  menuDeleteReducer,
  menuCreatReducer,
  menuListMyReducer,
  menuUpdateReducer,
  getMenuReducer,
} from "./reducers/menuReducers";

import { composeWithDevTools } from "redux-devtools-extension";
import {
  publicCategoryListReducer,
  publicInfoReducer,
  publicSearchUser,
  visitorUserMenuReducer,
} from "./reducers/publicReducers";
import {
  getWifiReducer,
  wifiCreatReducer,
  wifiDeleteReducer,
  wifiListMyReducer,
  wifiUpdateReducer,
} from "./reducers/wifiReducers";
import {
  getImageReducer,
  imageCreatReducer,
  imageDeleteReducer,
  imageListMyReducer,
  imageUpdateReducer,
} from "./reducers/imageReducers";
import {
  getSocialMediaReducer,
  socialMediaCreatReducer,
  socialMediaDeleteReducer,
  socialMediaListMyReducer,
  socialMediaUpdateReducer,
} from "./reducers/socialMediaReducers";
import {
  getpaymentReducer,
  paymentCreatReducer,
  paymentDeleteReducer,
  paymentListMyReducer,
  paymentUpdateReducer,
} from "./reducers/paymentReducers";
import {
  discountUpdateReducer,
  getDiscountReducer,
} from "./reducers/discountReducers";
import { chngePremiumStatusReducer } from "./reducers/premiumReducers";

const reducer = combineReducers({
  publicCategoryList: publicCategoryListReducer,
  publicVisitorUser: visitorUserMenuReducer,
  publicInfo: publicInfoReducer,
  userSearch: publicSearchUser,

  productList: productListMyReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreatReducer,
  productUpdate: productUpdateReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userProfileDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userChangePassword: userChangePasswordReducer,
  userResetPassword: userResetPasswordReducer,

  userAll: getAllUsersReducer,
  changeUserPremiumStatus: chngePremiumStatusReducer,

  categoryCreate: categoryCreatReducer,
  categoryListMy: categoryListMyReducer,
  categoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryRestore: restoreCreatReducer,

  menuCreate: menuCreatReducer,
  menuListMy: menuListMyReducer,
  menuDelete: menuDeleteReducer,
  menuUpdate: menuUpdateReducer,
  getMenuNow: getMenuReducer,

  imageCreate: imageCreatReducer,
  imageListMy: imageListMyReducer,
  imageDelete: imageDeleteReducer,
  imageUpdate: imageUpdateReducer,
  getImageNow: getImageReducer,

  socialMediaCreate: socialMediaCreatReducer,
  socialMediaListMy: socialMediaListMyReducer,
  socialMediaDelete: socialMediaDeleteReducer,
  socialMediaUpdate: socialMediaUpdateReducer,
  getSocialMediaNow: getSocialMediaReducer,

  wifiList: wifiListMyReducer,
  wifiDelete: wifiDeleteReducer,
  wifiCreate: wifiCreatReducer,
  wifiUpdate: wifiUpdateReducer,
  getWifiNow: getWifiReducer,

  paymentList: paymentListMyReducer,
  paymentDelete: paymentDeleteReducer,
  paymentCreate: paymentCreatReducer,
  paymentUpdate: paymentUpdateReducer,
  getpaymentNow: getpaymentReducer,

  updateDiscount: discountUpdateReducer,
  getDiscount: getDiscountReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
