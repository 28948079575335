import {
  DISCOUNT_UPDATE_REQUEST,
  DISCOUNT_UPDATE_SUCCESS,
  DISCOUNT_UPDATE_FAIL,
  DISCOUNT_UPDATE_RESET,
  DISCOUNT_GET_REQUEST,
  DISCOUNT_GET_SUCCESS,
  DISCOUNT_GET_FAIL,
  DISCOUNT_GET_RESET,
} from "../constants/discountConstants";

export const discountUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNT_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case DISCOUNT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        discount: action.payload,
      };
    case DISCOUNT_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case DISCOUNT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getDiscountReducer = (state = {}, action) => {
  switch (action.type) {
    case DISCOUNT_GET_REQUEST:
      return {
        loading: true,
      };

    case DISCOUNT_GET_SUCCESS:
      return {
        loading: false,
        discount: action.payload,
      };
    case DISCOUNT_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case DISCOUNT_GET_RESET:
      return {};

    default:
      return state;
  }
};
