export const CATEGORY_CREAT_REQUEST = "CATEGORY_CREAT_REQUEST";
export const CATEGORY_CREAT_SUCCESS = "CATEGORY_CREAT_SUCCESS";
export const CATEGORY_CREAT_FAIL = "CATEGORY_CREAT_FAIL";
export const CATEGORY_CREAT_RESET = "CATEGORY_CREAT_RESET";

export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAIL = "CATEGORY_UPDATE_FAIL";
export const CATEGORY_UPDATE_RESET = "CATEGORY_UPDATE_RESET";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";
export const CATEGORY_LIST_RESET = "CATEGORY_LIST_RESET";

export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";
export const CATEGORY_DELETE_RESET = "CATEGORY_DELETE_RESET";

export const CATEGORY_RESTORE_REQUEST = "CATEGORY_RESTORE_REQUEST";
export const CATEGORY_RESTORE_SUCCESS = "CATEGORY_RESTORE_SUCCESS";
export const CATEGORY_RESTORE_FAIL = "CATEGORY_RESTORE_FAIL";
export const CATEGORY_RESTORE_RESET = "CATEGORY_RESTORE_RESET";
