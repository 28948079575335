import axios from "axios";
import {
  IMAGE_GET_IMAGE_FAIL,
  IMAGE_GET_IMAGE_REQUEST,
  IMAGE_GET_IMAGE_SUCCESS,
  IMAGE_CREAT_FAIL,
  IMAGE_CREAT_REQUEST,
  IMAGE_CREAT_SUCCESS,
  IMAGE_DELETE_FAIL,
  IMAGE_DELETE_REQUEST,
  IMAGE_DELETE_SUCCESS,
  IMAGE_LIST_FAIL,
  IMAGE_LIST_REQUEST,
  IMAGE_LIST_SUCCESS,
  IMAGE_UPDATE_FAIL,
  IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_SUCCESS,
} from "../constants/imageConstants";
import { IMAGE_API } from "../utils/apiConfig";
import { userDetails } from "./userActions";

export const createImage = (image) => async (dispatch, getState) => {
  try {
    dispatch({
      type: IMAGE_CREAT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        enctype: "multipart/form-data",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(IMAGE_API, image, config);

    dispatch({
      type: IMAGE_CREAT_SUCCESS,
      payload: data,
    });

    dispatch(listMyImages());
  } catch (error) {
    dispatch({
      type: IMAGE_CREAT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const editImage = (image) => async (dispatch, getState) => {
  try {
    dispatch({
      type: IMAGE_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(IMAGE_API + "/" + image.id, image, config);

    dispatch({
      type: IMAGE_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch(userDetails(userInfo.token));
  } catch (error) {
    dispatch({
      type: IMAGE_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const deleteImage = (image) => async (dispatch, getState) => {
  try {
    dispatch({
      type: IMAGE_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(IMAGE_API + "/" + image.id, config);

    dispatch({
      type: IMAGE_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listMyImages());
  } catch (error) {
    dispatch({
      type: IMAGE_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getImage = (image) => async (dispatch, getState) => {
  try {
    dispatch({
      type: IMAGE_GET_IMAGE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // };

    const { data } = await axios.get(IMAGE_API + "/" + image);

    dispatch({
      type: IMAGE_GET_IMAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: IMAGE_GET_IMAGE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const listMyImages = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: IMAGE_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(IMAGE_API, config);

    dispatch({
      type: IMAGE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: IMAGE_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
