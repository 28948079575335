import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { listMyCategories } from "../../actions/categoryActions";
import AddCategory from "../../components/userMenuScreen/AddCategory";
import AddProduct from "../../components/userMenuScreen/AddProduct";
import ListCatProductUser from "../../components/userMenuScreen/ListCatProductUser";
import EditSettingMenu from "../../components/userMenuScreen/EditSettingMenu";
import { getMenu } from "../../actions/menuActions";
import AddPremiumCategory from "../../components/userMenuPremuimScreen/AddPremiumCategory";
import AddPremiumProduct from "../../components/userMenuPremuimScreen/AddPremiumProduct";
import ListPremiumCatProductUser from "../../components/userMenuPremuimScreen/ListPremiumCatProductUser";

const UserMenuScreen = () => {
  let navigate = useNavigate();

  const params = useParams();
  const menuId = params.id;

  const dispatch = useDispatch();
  const [optionIsSelected, setOptionIsSelected] = useState(false);

  const getMenuNow = useSelector((state) => state.getMenuNow);
  const { error, menu } = getMenuNow;

  useEffect(() => {
    dispatch(listMyCategories());
    dispatch(getMenu(menuId));
  }, [dispatch, menuId]);

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  });

  return (
    <main className="main-container">
      <div className="form-check mt-3 mb-4">
        <h1 style={{ textAlign: "center" }}>Modèle de Menu 1</h1>
      </div>
      <Row style={{ margin: "10px 3px 30px 3px" }}>
        <Col
          onClick={() => {
            setOptionIsSelected(false);
          }}
          style={{
            backgroundColor: "#F1F1F1",
            textAlign: "center",
            padding: "10px",
            background: !optionIsSelected ? "#d1f1ff" : "",
          }}
        >
          <h3>Menu</h3>
        </Col>
        <Col
          onClick={() => {
            setOptionIsSelected(true);
          }}
          style={{
            backgroundColor: "#F1F1F1",
            textAlign: "center",
            padding: "10px",
            background: optionIsSelected ? "#d1f1ff" : "",
          }}
        >
          <h3>Paramètre du Menu</h3>
        </Col>
      </Row>

      {optionIsSelected ? (
        <EditSettingMenu menuId={menuId} />
      ) : (
        <>
          {menu && menu.is_premium == 0 ? (
            <>
              <AddCategory />
              <AddProduct menuId={menuId} />
              <ListCatProductUser />
            </>
          ) : (
            menu && (
              <>
                <AddPremiumCategory />
                <AddPremiumProduct menuId={menuId} />
                <ListPremiumCatProductUser />
              </>
            )
          )}
        </>
      )}
      <div className="mb-5"></div>
    </main>
  );
};

export default UserMenuScreen;
