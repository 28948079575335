import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { listPublicCategories } from "../../actions/publicActions";
import Loader from "../../components/Loader";

const PremiumMenuScreen = () => {
  const navigate = useNavigate();

  const menuList = useSelector((state) => state.publicCategoryList);
  const { data, error, loading } = menuList;

  useEffect(() => {
    if (error) navigate("/");
  }, [error, navigate]);

  document.body.style.backgroundColor = "rgb(61, 60, 60)";
  document.body.style.minHeight = "100vh";
  useEffect(() => () => setColors(), []);

  const setColors = () => {
    document.body.style.backgroundColor = "";
    document.body.style.minHeight = "";
  };

  return (
    <div style={{ backgroundColor: "#3d3c3c" }}>
      <Helmet>
        <title> Menu</title>
      </Helmet>
      {!loading ? (
        <main className="container pt-5">
          {data &&
            data.categories &&
            data.categories.map((cat) => (
              <div key={cat.id * 2}>
                <button
                  className={
                    "btn btn-primary mt-2 rounded-0 " +
                    (cat.image != null ? "premium-dropdown" : "")
                  }
                  type="button"
                  style={{
                    backgroundColor: data.menu.category_color
                      ? data.menu.category_color
                      : "auto",
                    backgroundImage: "url(" + cat.image + ")",
                    height: "120px",
                    margin: "auto",
                    paddingTop: "45px",
                  }}
                  data-bs-toggle="collapse"
                  data-bs-target={"#collapseone" + cat.id}
                  aria-expanded="false"
                >
                  {cat.name}
                  <i className="fas fa-caret-down"></i>
                </button>

                <div
                  className={"collapse " + data.menu.food_color}
                  id={"collapseone" + cat.id}
                >
                  <div className="card card-body premium-cardbody">
                    {cat.products &&
                      cat.products.map((product) => (
                        <div key={product.id * 2}>
                          <div className="horizontal-position">
                            <div className="border-box-1">
                              <nobr>{product.name}</nobr>
                            </div>

                            <div className="border-box-1">
                              <strong> {product.price}DT</strong>
                            </div>
                          </div>
                          <div
                            className="mt-3"
                            style={{
                              display: "flex",
                            }}
                          >
                            {((product.description &&
                              product.description.length > 0) ||
                              product.image) && (
                              <>
                                <p
                                  className="p-2 product-description mb-4 text-sm-left"
                                  style={{ width: "100%" }}
                                >
                                  {product.description}
                                </p>
                                <div>
                                  {product.image && (
                                    <img
                                      style={{
                                        maxWidth: "115px",
                                        maxHeight: "115px",
                                        margin: "20px",
                                        marginTop: "0",
                                      }}
                                      src={product.image}
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </main>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PremiumMenuScreen;
