import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { userDetails } from "../../actions/userActions";
import Loader from "../../components/Loader";
import { toastAlert } from "../../utils/alert";

const LoginWithTokenScreen = () => {
  const params = useParams();
  const token = params.token;

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userDetails);
  const { loading, error, success } = user;

  useEffect(() => {
    dispatch(userDetails(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (error) {
      toastAlert("Token invalid", "error");
      navigate("/");
    }
  }, [error, navigate]);
  useEffect(() => {
    if (success) navigate("/user/dashboard");
  }, [navigate, success]);

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Se connecter</h1>
      <div>{loading && <Loader />}</div>
    </div>
  );
};

export default LoginWithTokenScreen;
