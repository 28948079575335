import {
  PAYMENT_CREAT_FAIL,
  PAYMENT_CREAT_RESET,
  PAYMENT_CREAT_SUCCESS,
  PAYMENT_DELETE_FAIL,
  PAYMENT_DELETE_REQUEST,
  PAYMENT_DELETE_RESET,
  PAYMENT_DELETE_SUCCESS,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_RESET,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_UPDATE_FAIL,
  PAYMENT_UPDATE_REQUEST,
  PAYMENT_UPDATE_RESET,
  PAYMENT_UPDATE_SUCCESS,
  PAYMENT_CREAT_REQUEST,
  PAYMENT_GET_REQUEST,
  PAYMENT_GET_SUCCESS,
  PAYMENT_GET_FAIL,
  PAYMENT_GET_RESET,
} from "../constants/paymentConstants";

export const paymentCreatReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_CREAT_REQUEST:
      return {
        loading: true,
      };

    case PAYMENT_CREAT_SUCCESS:
      return {
        loading: false,
        success: true,
        payment: action.payload,
      };
    case PAYMENT_CREAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PAYMENT_CREAT_RESET:
      return {};
    default:
      return state;
  }
};

export const paymentUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case PAYMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        payment: action.payload,
      };
    case PAYMENT_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PAYMENT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const paymentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_DELETE_REQUEST:
      return {
        loading: true,
      };

    case PAYMENT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        payment: action.payload,
      };
    case PAYMENT_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PAYMENT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const paymentListMyReducer = (state = { payments: [] }, action) => {
  switch (action.type) {
    case PAYMENT_LIST_REQUEST:
      return {
        loading: true,
      };

    case PAYMENT_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        payments: action.payload,
      };
    case PAYMENT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PAYMENT_LIST_RESET:
      return { payments: [] };

    default:
      return state;
  }
};

export const getpaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_GET_REQUEST:
      return {
        loading: true,
      };

    case PAYMENT_GET_SUCCESS:
      return {
        loading: false,
        payment: action.payload,
      };
    case PAYMENT_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PAYMENT_GET_RESET:
      return {};

    default:
      return state;
  }
};
