import axios from "axios";
import {
  MENU_GET_MENU_FAIL,
  MENU_GET_MENU_REQUEST,
  MENU_GET_MENU_SUCCESS,
  MENU_CREAT_FAIL,
  MENU_CREAT_REQUEST,
  MENU_CREAT_SUCCESS,
  MENU_DELETE_FAIL,
  MENU_DELETE_REQUEST,
  MENU_DELETE_SUCCESS,
  MENU_LIST_FAIL,
  MENU_LIST_REQUEST,
  MENU_LIST_SUCCESS,
  MENU_UPDATE_FAIL,
  MENU_UPDATE_REQUEST,
  MENU_UPDATE_SUCCESS,
} from "../constants/menuConstants";
import { MENU_API } from "../utils/apiConfig";
import { listMyCategories } from "./categoryActions";

export const createMenu = (menu) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MENU_CREAT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(MENU_API, menu, config);

    dispatch({
      type: MENU_CREAT_SUCCESS,
      payload: data,
    });

    dispatch(listMyMenus());
  } catch (error) {
    dispatch({
      type: MENU_CREAT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const editMenu = (menu) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MENU_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(MENU_API + "/" + menu.id, menu, config);

    dispatch({
      type: MENU_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch(getMenu(menu.id));
    dispatch(listMyCategories());
  } catch (error) {
    dispatch({
      type: MENU_UPDATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const deleteMenu = (menu) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MENU_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(MENU_API + "/" + menu.id, config);

    dispatch({
      type: MENU_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(listMyMenus());
  } catch (error) {
    dispatch({
      type: MENU_DELETE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getMenu = (menu) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MENU_GET_MENU_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(MENU_API + "/" + menu, config);

    dispatch({
      type: MENU_GET_MENU_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MENU_GET_MENU_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const listMyMenus = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MENU_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(MENU_API, config);

    dispatch({
      type: MENU_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MENU_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
