import axios from "axios";
import {
  CHANGE_PREMIUM_STATUS_FAIL,
  CHANGE_PREMIUM_STATUS_REQUEST,
  CHANGE_PREMIUM_STATUS_SUCCESS,
} from "../constants/premiumConstants";

import { CHANGE_PREMIUM_STATUS_API } from "../utils/apiConfig";
import { getAllUsers } from "./userActions";

export const changePremiumStatus = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHANGE_PREMIUM_STATUS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(CHANGE_PREMIUM_STATUS_API + id, config);

    dispatch({
      type: CHANGE_PREMIUM_STATUS_SUCCESS,
      payload: data,
    });
    dispatch(getAllUsers());
  } catch (error) {
    dispatch({
      type: CHANGE_PREMIUM_STATUS_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
