import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userRegister } from "../../actions/userActions";
import Loader from "../../components/Loader";

const RegisterScreen = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    city: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    city: "",
  });

  const userRegist = useSelector((state) => state.userRegister);
  const { loading, error } = userRegist;
  const dispatch = useDispatch();

  useEffect(() => {
    if (error && error.email && error.email[0].length > 0) {
      setFormErrors({ ...formErrors, email: "L'e-mail existe déjà" });
    }
  }, [error]);

  const validForm = () => {
    return (
      validateField("name", formData.name) &&
      validateField("phone", formData.phone) &&
      validateField("email", formData.email) &&
      validateField("password", formData.password) &&
      validateField("confirmPassword", formData.confirmPassword)
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (validForm()) {
      dispatch(
        userRegister(
          formData.name,
          formData.email,
          formData.password,
          formData.phone,
          formData.city
        )
      );
    }
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    validateField(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (fieldName, value) => {
    let error,
      msg = "";
    switch (fieldName) {
      case "name":
        error = value.length >= 1;
        msg = error ? "" : "Le nom est obligatoire";
        break;
      case "phone":
        error = value.length >= 8;
        msg = error ? "" : "Le téléphone est obligatoire";
        break;
      case "email":
        error = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        msg = error ? "" : "Merci de vérifier votre email";
        break;
      case "password":
        error = value.length >= 6;
        msg = error ? "" : "Le mot de passe doit être supérieur à 6 caractères";
        break;
      case "confirmPassword":
        error = value.length >= 6 && formData.password === value;
        msg = error
          ? ""
          : "Le mot de passe de confirmation doit être le même que le mot de passe";
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [fieldName]: msg });
    return error;
  };

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Register</h1>
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name" className="mt-4">
          <Form.Label> Nom d'entreprise </Form.Label>
          <Form.Control
            type="text"
            placeholder="Nom d'entreprise (vous ne pouvez pas changer le nom d'utilisateur après avoir créé ce compte)"
            value={formData.name}
            name="name"
            onChange={(e) => handleUserInput(e)}
            className={formErrors["name"].length > 0 && "inputError"}
          ></Form.Control>
          {formErrors["name"].length > 0 && (
            <p className="alert alert-danger descError">{formErrors["name"]}</p>
          )}
        </Form.Group>

        <Form.Group controlId="phone" className="mt-4">
          <Form.Label> Numéro de téléphone </Form.Label>
          <Form.Control
            type="text"
            placeholder="Numéro de téléphone"
            name="phone"
            value={formData.phone}
            className={formErrors["phone"].length > 0 && "inputError"}
            onChange={(e) => handleUserInput(e)}
          ></Form.Control>
          {formErrors["phone"].length > 0 && (
            <p className="alert alert-danger descError">
              {formErrors["phone"]}
            </p>
          )}
        </Form.Group>

        <Form.Group controlId="email" className="mt-4">
          <Form.Label> Adresse Email </Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Adresse Email"
            value={formData.email}
            onChange={(e) => handleUserInput(e)}
            className={formErrors["email"].length > 0 && "inputError"}
          ></Form.Control>
          {formErrors["email"].length > 0 && (
            <p className="alert alert-danger descError">
              {formErrors["email"]}
            </p>
          )}
        </Form.Group>
        <Form.Group controlId="city" className="mt-4">
          <Form.Label>Ville</Form.Label>
          <Form.Select
            onChange={(e) => handleUserInput(e)}
            name="city"
            aria-label="Default select example"
          >
            <option value="Default">Default</option>
            <option value="Tunis">Tunis</option>
            <option value="Sfax">Sfax</option>
            <option value="Béja">Béja</option>
            <option value="Gafsa">Gafsa</option>
            <option value="Sidi bouzid">Sidi bouzid</option>
            <option value="Gabés">Gabés</option>
            <option value="Gbeli">Gbeli</option>
            <option value="Mednine">Mednine</option>
            <option value="Tataouine">Tataouine</option>
            <option value="Gassrine">Gassrine</option>
            <option value="Mahdia">Mahdia</option>
            <option value="Kairouan">Kairouan</option>
            <option value="El kef">El kef</option>
            <option value="Sousse">Sousse</option>
            <option value="Bizerte">Bizerte</option>
            <option value="Nabeul">Nabeul</option>
            <option value="Siliana">Siliana</option>
            <option value="Jandouba">Jandouba</option>
            <option value="Tozeur">Tozeur</option>
            <option value="Monastir">Monastir</option>
            <option value="Zaghouan">Zaghouan</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="password" className="mt-4">
          <Form.Label> Mot de passe</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Mot de passe "
            value={formData.password}
            className={formErrors["password"].length > 0 && "inputError"}
            onChange={(e) => handleUserInput(e)}
          ></Form.Control>
          {formErrors["password"].length > 0 && (
            <p className="alert alert-danger descError">
              {formErrors["password"]}
            </p>
          )}
        </Form.Group>

        <Form.Group controlId="confirmPassword" className="mt-4">
          <Form.Label> Confirmer Mot de passe </Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            placeholder="Confirmer Mot de passe "
            value={formData.confirmPassword}
            className={formErrors["confirmPassword"].length > 0 && "inputError"}
            onChange={(e) => handleUserInput(e)}
          ></Form.Control>
          {formErrors["confirmPassword"].length > 0 && (
            <p className="alert alert-danger descError">
              {formErrors["confirmPassword"]}
            </p>
          )}
        </Form.Group>

        <Row className="py-3">
          <Col>
            <Button
              type="submit"
              style={{ marginTop: "0" }}
              className="buttonn-design"
              variant=""
            >
              S'inscrire
            </Button>
          </Col>
          <Col style={{ textAlign: "right", marginTop: "10px" }}>
            Avez-vous un compte ? <Link to={"/login"}>Se connecter </Link>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RegisterScreen;
