import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { publicIndexInfo } from "../../actions/publicActions";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const publicInfo = useSelector((state) => state.publicInfo);
  const { data } = publicInfo;

  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;

  useEffect(() => {
    if (!data) dispatch(publicIndexInfo());
  }, [dispatch]);

  return (
    <>
      <section className="main-container">


      <div className="hero" style={{backgroundImage: `url("/images/hero.jpg")` }}>
    
        <div class="column text-center">
        <div class="title-style">
          Bienvenue sur <span style={{padding: "5px" ,backgroundColor:"black", color: "white"}}>Qrcode.tn</span> </div>
       
        <div class="subtitle">
        La plus grande communauté de QR code dans la Tunisie
        </div>

        <div class="subtitle">
        Chaque compte possède son propre QR Code, pour un accès rapide et sans attente.
        </div>
       

        <div class="btn-style">
           

          {userInfo ? (
          ""
        ) : (
          <div className="row button-design-container">
            <div className="col-sm-6 homePage">
              <Link to="/register">
                <button
                  className="main-btn"
                  style={{ display: "initial", width: "auto" }}
                >
                 Créer un compte
                </button>
              </Link>
            </div>
            <div
              className="col-sm-6 homePage"
              style={{
                textAlign: "left",
              }}
            >
              <Link to="/login">
                <button
                  className="main-btn"
                  style={{ display: "initial", width: "auto" }}
                >
                  Mon compte
                </button>
              </Link>
            </div>
          </div>
        )}

        
         
      
      </div>
      </div>
      </div>

      <section>

      <div className="code">
          <img src="/images/logo.png" alt="codeqr" />
        </div>

        <div className="row col-sm-12">
          <div className="col-sm-4"></div>
          <div
            style={{
              textAlign: "center",
              marginTop: "30px",
            }}
            className="col-sm-4"
          >
            <h2>Visiteurs </h2>
            <span style={{ fontSize: "50px", fontWeight: "600" }}>
              {data ? data.users : 0}
            </span>
          </div>

          <div className="col-sm-4"></div>
        </div>

        
      </section>


       
     
        
      <section class="row section-two">
      <h3 className="text-center mb-5">
      Suivez les nouveaux avantages de <strong>Qrcode.tn</strong>
           </h3>
        
        <div class="descption-box col-md-6">
          
          
           
          <ul>
            <li>
              <i class="far fa-check-circle"></i>
             Vous pouvez créer/modifier votre menu instantanément
            </li>
            <li>
              <i class="far fa-check-circle"></i>
              Partagez vos médias sociaux, votre emplacement, vos coordonnées, votre site Web.
                </li>
            <li>
              <i class="far fa-check-circle"></i>
              Créez des événements/réductions pour augmenter les interactions avec vos clients
            </li>
            <br></br>



            <li>
              <i class="far fa-check-circle"></i>
              Méthodes de paiement disponibles et société de livraison que vous soutenez
            </li>

        

            <li>
              <i class="far fa-check-circle"></i>
Système de commande pour votre client            
</li>


            <li>
              <i class="far fa-check-circle"></i>

              Accéder à la communauté avec plus de 500 marques à l'aide du moteur de recherche.
            </li>

            <br></br>

            <li>
              <i class="far fa-check-circle"></i>
              Système de statistiques pour suivre le nombre de visiteurs
            </li>
            
            <li>
              <i class="far fa-check-circle"></i>
              Ajoutez votre code Wi-fi
            </li>

            
            <li>
              <i class="far fa-check-circle"></i>
              Partagez vos photos
            </li>


            <br></br>

            
            <li>
              <i class="far fa-check-circle"></i>
              Plateforme gratuite
            </li>
             
           
          </ul>
          </div>
        <div className="col-md-6">
          <img src="images/section-2-img.jpg" alt="illustration of qrcode" style={{width: "100%", padding: "10px"}}/>
        </div>
        
      </section>


      <section class="row section-two">
      <h3 className="text-center mb-5">
      Suivez les nouveaux avantages de <strong>Qrcode.tn</strong>
           </h3>
        
        <div class="descption-box col-md-6">
          
          
           
          <ul>
            <li>
              <i class="far fa-check-circle"></i>
             Vous pouvez créer/modifier votre menu instantanément
            </li>
            <li>
              <i class="far fa-check-circle"></i>
              Partagez vos médias sociaux, votre emplacement, vos coordonnées, votre site Web.
                </li>
            <li>
              <i class="far fa-check-circle"></i>
              Créez des événements/réductions pour augmenter les interactions avec vos clients
            </li>
            <br></br>



            <li>
              <i class="far fa-check-circle"></i>
              Méthodes de paiement disponibles et société de livraison que vous soutenez
            </li>

        

            <li>
              <i class="far fa-check-circle"></i>
Système de commande pour votre client            
</li>


            <li>
              <i class="far fa-check-circle"></i>

              Accéder à la communauté avec plus de 500 marques à l'aide du moteur de recherche.
            </li>

            <br></br>

            <li>
              <i class="far fa-check-circle"></i>
              Système de statistiques pour suivre le nombre de visiteurs
            </li>
            
            <li>
              <i class="far fa-check-circle"></i>
              Ajoutez votre code Wi-fi
            </li>

            
            <li>
              <i class="far fa-check-circle"></i>
              Partagez vos photos
            </li>


            <br></br>

            
            <li>
              <i class="far fa-check-circle"></i>
              Plateforme gratuite
            </li>
             
           
          </ul>
          </div>
        <div className="col-md-6">
          <img src="images/section-2-img.jpg" alt="illustration of qrcode" style={{width: "100%", padding: "10px"}}/>
        </div>
        
      </section>



      
       
      </section>


      <section class="section-three">
        <h2 class="mt-5 text-center">Nos partners</h2>
        
        <div class="container text-center">
          
          <div class="row mx-auto my-auto justify-content-center">
              <div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-inner" >
                      <div class="carousel-item active">
                          <div class="carousel-set">
                              
                            
                                    <div><img src="images/partners/partner-1.png" alt="logo" class="img-design"></img></div> 
                                    <div><img src="images/partners/partner-1.png" alt="logo" class="img-design"></img></div> 
                                    
                      </div>
                      <div class="carousel-item">
                          <div class="carousel-set">
                              
                                  <div><img src="images/partners/partner-1.png" alt="logo" class="img-design"></img></div> 
                                    <div><img src="images/partners/partner-1.png" alt="logo" class="img-design"></img></div> 
                                    <div><img src="images/partners/partner-1.png" alt="logo" class="img-design"></img></div> 
                                  
                          </div>
                      </div>


                      <div class="carousel-item">
                          <div class="carousel-set">
                              
                                  <div><img src="images/partners/partner-1.png" alt="logo" class="img-design"></img></div> 
                                    <div><img src="images/partners/partner-1.png" alt="logo" class="img-design"></img></div> 
                                    <div><img src="images/partners/partner-1.png" alt="logo" class="img-design"></img></div> 
                                  
                          </div>
                      </div>
                    
                    
                      
                      
                  </div>
                  <a class="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="prev">
                      <span style={{ backgroundColor: "black"}} class="carousel-control-prev-icon" aria-hidden="true"></span>
                  </a>
                  <a class="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
                      <span style={{ backgroundColor: "black"}}class="carousel-control-next-icon" aria-hidden="true"></span>
                  </a>
              </div>
          </div>
         
      </div>
        
        
      </div>
        
        
        
     
      </section>

    </>
  );
};

export default HomeScreen;
