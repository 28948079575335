import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { visitorSearchUser } from "../actions/publicActions";
import { userLogout } from "../actions/userActions";
import { PUBLIC_SEARCH_RESET } from "../constants/publicConstants";
import Loader from "./Loader";

const Header = () => {
  let navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userSearch = useSelector((state) => state.userSearch);
  const { data, loading } = userSearch;

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  useEffect(() => {
    document.getElementById("closeMenu").click();
  }, [navigate]);
  const logoutHandler = () => {
    navigate("/");
    dispatch(userLogout());
  };

  const searchNow = (e) => {
    setSearch(e);
    if (e.length >= 2) {
      dispatch(visitorSearchUser(e));
    }
  };

  const resetSearch = (id) => {
    navigate("/" + id);
    dispatch({ type: PUBLIC_SEARCH_RESET });
    setSearch("");
  };
  return (
    <div>
      <nav className="navbar navbar-dark bg-dark">
        <div className="container-fluid">
          {window.location.pathname === "/" ? (
            userInfo && userInfo?.image && userInfo?.image !== "" ? (
              <img
                style={{ borderRadius: "50%" }}
                src={userInfo.image}
                width="50"
                height="50"
                alt="codeqr"
              />
            ) : (
              <Link
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  style={{ borderRadius: "50%" }}
                  src="/images/logo-mini.png"
                  width="50"
                  alt="codeqr"
                />
              </Link>
            )
          ) : (
            <Link
              onClick={() => {
                if (window.location.pathname !== "/") navigate(-1);
              }}
            >
              <i
                className="fas fa-arrow-left"
                style={{ paddingLeft: "5px", fontSize: "24px", color: "white" }}
              ></i>
            </Link>
          )}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end text-bg-dark"
            tabIndex="-1"
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                <Link to="/" style={{ color: "white" }}>
                  Qrcode.tn
                </Link>
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                id="closeMenu"
              ></button>
            </div>
            <div className="offcanvas-body">
              <form
                className="d-flex mb-3"
                role="search"
                style={{ position: "relative" }}
              >
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Chercher un café ou un resto..."
                  aria-label="Search"
                  value={search}
                  onChange={(e) => searchNow(e.target.value)}
                />

                {search.length >= 2 ? (
                  <ul className="searchResult">
                    {loading ? (
                      <li style={{ textAlign: "center" }}>
                        <Loader />
                      </li>
                    ) : (
                      data &&
                      data.map((user) => (
                        <li
                          key={user.id}
                          onClick={() => resetSearch(user.slug)}
                        >
                          <Image style={{borderRadius : "50%"
                          }} src={user.image ?? "/images/avatar.png"} />
                          <span> {user.name}</span>
                        </li>
                      ))
                    )}
                  </ul>
                ) : (
                  ""
                )}

                {/* <button
                  className="btn-outline-success search-btn"
                  type="submit"
                >
                  Search
                </button> */}
              </form>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {!userInfo ? (
                  <>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        <i className="fa fa-user"></i> Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/login" className="nav-link">
                        <i className="fas fa-sign-in-alt"></i> Login in
                      </Link>
                    </li>
                    <hr></hr>
                    <li className="nav-item">
                      <Link to="/contact" className="nav-link">
                        <i className="fas fa-phone"></i> Contact
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/accountpackage" className="nav-link">
                        <i className="fas fa-user-tie"></i> Tarifs des comptes
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adspackage" className="nav-link">
                        <i className="fas fa-ad"></i> Tarifs des publicité
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/tutorial" className="nav-link">
                        <i className="fas fa-play"></i> Tutorials
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    {userInfo.role === "admin" ? (
                      <>
                        <li className="nav-item">
                          <Link className="nav-link" to="/admin/users">
                            <i className="fas fa-users"></i> Liste Utilisateurs
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-item">
                          <Link className="nav-link" to="/user/dashboard">
                            <i className="fa fa-pen"></i> Dashboard
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link className="nav-link" to={"/" + userInfo?.slug}>
                            <i className="fas fa-eye"></i> Voir Ma Carte
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link className="nav-link" to="/user/statistique">
                            <i className="fas fa-chart-bar"></i> Statistique
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/get/qrcode">
                            <i className="fas fa-qrcode"></i> Obtenir Mon QR
                            Code
                          </Link>
                        </li>
                      </>
                    )}

                    <li className="nav-item">
                      <Link className="nav-link" to="/user/edit/profile">
                        <i className="fas fa-user-circle"></i> Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={() => logoutHandler()}
                        className="nav-link"
                      >
                        <i className="fas fa-sign-out-alt"></i> Logout
                      </Link>
                    </li>

                    <hr></hr>

                    <li className="nav-item">
                      <Link to="/contact" className="nav-link">
                        <i className="fas fa-phone"></i> Contact
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/accountpackage" className="nav-link">
                        <i className="fas fa-user-tie"></i> Tarifs des comptes
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/adspackage" className="nav-link">
                        <i className="fas fa-ad"></i> Tarifs des publicité
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/tutorial" className="nav-link">
                        <i className="fas fa-play"></i> Tutorials
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
