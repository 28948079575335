import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../actions/userActions";
import Loader from "../../components/Loader";
import QRCode from "react-qr-code";
import { frontUrl } from "../../utils/apiConfig";
import { Link, useNavigate } from "react-router-dom";
import toImg from "react-svg-to-image";

const QrcodeUser = () => {
  let navigate = useNavigate();

  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;
  const downloadSVG = async () => {
    toImg("svg", userInfo?.slug, {
      scale: 1,
      format: "png",
      quality: 1,
      download: true,
    }).then((fileData) => {});
  };

  return (
    <React.Fragment>
      <h1 className="title">Votre QR code</h1>
      <div className="code" id="RCODE">
        <QRCode size={256} value={frontUrl + "/" + userInfo?.slug} />
      </div>
      <div style={{ textAlign: "center" }} className="mt-5">
        <button
          className="buttonn-design"
          onClick={() => downloadSVG()}
          style={{ width: "auto" }}
        >
          {" "}
          Télécharger QR{" "}
        </button>
        <br />
        <Link to={"/" + userInfo?.slug}>
          <button className="buttonn-design mt-5">Voir mon QR</button>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default QrcodeUser;
