import {
  IMAGE_GET_IMAGE_FAIL,
  IMAGE_GET_IMAGE_REQUEST,
  IMAGE_GET_IMAGE_SUCCESS,
  IMAGE_CREAT_FAIL,
  IMAGE_CREAT_REQUEST,
  IMAGE_CREAT_RESET,
  IMAGE_CREAT_SUCCESS,
  IMAGE_DELETE_FAIL,
  IMAGE_DELETE_REQUEST,
  IMAGE_DELETE_RESET,
  IMAGE_DELETE_SUCCESS,
  IMAGE_LIST_FAIL,
  IMAGE_LIST_REQUEST,
  IMAGE_LIST_RESET,
  IMAGE_LIST_SUCCESS,
  IMAGE_UPDATE_FAIL,
  IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_RESET,
  IMAGE_UPDATE_SUCCESS,
} from "../constants/imageConstants";

export const imageCreatReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_CREAT_REQUEST:
      return {
        loading: true,
      };

    case IMAGE_CREAT_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case IMAGE_CREAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case IMAGE_CREAT_RESET:
      return {};
    default:
      return state;
  }
};

export const imageUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case IMAGE_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case IMAGE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case IMAGE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const imageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_DELETE_REQUEST:
      return {
        loading: true,
      };

    case IMAGE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case IMAGE_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case IMAGE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const imageListMyReducer = (state = { images: [] }, action) => {
  switch (action.type) {
    case IMAGE_LIST_REQUEST:
      return {
        loading: true,
        images: [],
      };

    case IMAGE_LIST_SUCCESS:
      return {
        loading: false,
        images: action.payload,
      };
    case IMAGE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case IMAGE_LIST_RESET:
      return { images: [] };

    default:
      return state;
  }
};

export const getImageReducer = (state = { images: [] }, action) => {
  switch (action.type) {
    case IMAGE_GET_IMAGE_REQUEST:
      return {
        loading: true,
        images: [],
      };

    case IMAGE_GET_IMAGE_SUCCESS:
      return {
        loading: false,
        images: action.payload,
      };
    case IMAGE_GET_IMAGE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
