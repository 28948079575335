import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editWifi } from "../../actions/wifiActions";
import { toastAlert } from "../../utils/alert";
import Loader from "../Loader";

const AddUserWifi = () => {
  const dispatch = useDispatch();

  const wifiUpdate = useSelector((state) => state.wifiUpdate);

  const getWifiNow = useSelector((state) => state.getWifiNow);

  const {
    // loading: loadingGet,
    wifi: wifiGet,
    // success: successGet,
  } = getWifiNow; // hhhh

  const { loading, success } = wifiUpdate;

  const [status, setStatus] = useState(false);

  const [formData, setFormData] = useState({
    name: wifiGet != null ? wifiGet.name : "",
    password: wifiGet != null ? wifiGet.password : "",
  });

  const [formErrors, setFormErrors] = useState({
    password: "",
    name: "",
  });

  useEffect(() => {
    if (wifiGet) {
      setFormData({
        name: wifiGet.name,
        password: wifiGet.password,
      });
      setStatus(wifiGet.status == "1" ? true : false);
    }
  }, [wifiGet]);

  useEffect(() => {
    if (success) {
      toastAlert("Votre wifi a été modifié");
    }
  }, [success]);

  const validForm = () => {
    return (
      validateField("name", formData.name) &&
      validateField("password", formData.password)
    );
  };

  const editWifiNow = () => {
    if (validForm()) {
      //   setsatusProduct(true);
      dispatch(
        editWifi({
          name: formData.name,
          password: formData.password,
          status: status ? "1" : "0",
          id: wifiGet.id,
        })
      );
    }
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    validateField(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (fieldName, value) => {
    let error,
      msg = "";
    switch (fieldName) {
      case "name":
        error = value.length >= 1;
        msg = error ? "" : "Le nom de Wi-Fi est obligatoire";
        break;
      case "password":
        error = value.length >= 1;
        msg = error ? "" : "Mot de passe est obligatoire";
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors, [fieldName]: msg });
    return error;
  };

  const changeStatus = () => {
    setStatus(!status);
  };
  return (
    <>
      <Row>
        <Col>
          <div className="label">Activer le Wi-Fi</div>
        </Col>

        <Col>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              style={{
                height: "30px",
                width: "70px !important",
              }}
              checked={status}
              onChange={() => {
                changeStatus();
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="mb-3">
        <input
          type="text"
          id="exampleFormControlInput1"
          placeholder="nom de wifi ! "
          value={formData.name}
          name="name"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["name"].length > 0 ? "inputError" : "")
          }
        />
        {formErrors["name"].length > 0 && (
          <p className="alert alert-danger descError">{formErrors["name"]}</p>
        )}
      </div>
      <div className="mb-3">
        <input
          type="text"
          id="exampleFormControlInput1"
          placeholder="mot de passse"
          value={formData.password}
          name="password"
          onChange={(e) => handleUserInput(e)}
          className={
            "form-control " +
            (formErrors["password"].length > 0 ? "inputError" : "")
          }
        />
        {formErrors["password"].length > 0 && (
          <p className="alert alert-danger descError">
            {formErrors["password"]}
          </p>
        )}
      </div>

      <button
        className="light-btn addbtn"
        onClick={() => {
          editWifiNow();
        }}
        disabled={loading}
      >
        {loading ? <Loader /> : "Sauvegarder"}
      </button>
    </>
  );
};

export default AddUserWifi;
