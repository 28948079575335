import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { restoreCategory } from "../../actions/categoryActions";
import { editMenu } from "../../actions/menuActions";
import { toastAlert } from "../../utils/alert";
import Loader from "../Loader";

const EditSettingMenu = ({ menuId }) => {
  const dispatch = useDispatch();

  const getMenuNow = useSelector((state) => state.getMenuNow);
  const { menu } = getMenuNow;

  const menuUpdate = useSelector((state) => state.menuUpdate);
  const { loading, success } = menuUpdate;

  const categoryRestore = useSelector((state) => state.categoryRestore);
  const { loading: loadingRestore, success: successRestore } = categoryRestore;

  const [catColor, setCatColor] = useState(menu && menu.category_color);
  const [menuColor, setMenuColor] = useState(menu && menu.food_color);
  const [classer, setclasser] = useState("asc");
  const [statusChange, setStatusChange] = useState(false);
  const [status, setStatus] = useState(
    menu && menu.status ? (menu.status == "1" ? true : false) : false
  );

  const editMenuNow = () => {
    setStatusChange(true);
    dispatch(
      editMenu({
        id: menuId,
        category_color: catColor,
        food_color: menuColor,
        classer,
        name: menu.is_premium == 0 ? "Modele 1" : "Modele 2",
        status: status ? 1 : 0,
      })
    );
  };

  const changeStatus = () => {
    setStatus(!status);
  };

  useEffect(() => {
    if (success && statusChange === true) {
      setStatusChange(false);
      toastAlert("Votre menu a été modifiée");
    }
  }, [statusChange, success]);

  useEffect(() => {
    if (successRestore) {
      toastAlert("Votre categorie a été modifiée");
    }
  }, [statusChange, successRestore]);

  const restoreCategorie = () => {
    dispatch(restoreCategory());
  };

  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <div className="label">Activer Le Menu</div>
        </Col>

        <Col>
          <div className="form-check form-switch">
            <input
              style={{
                height: "30px",
                width: "70px !important",
              }}
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={status}
              onChange={() => {
                changeStatus();
              }}
            />
          </div>
        </Col>
      </Row>
      <div>
        <div className="label">Couleur de Menu</div>
        <div className="align">
          <input
            type="text"
            className="form-control"
            placeholder="choisis une couleur ! "
            value={catColor}
            onChange={() => {}}
          />
          <input
            type="color"
            id="color"
            style={{
              maxWidth: "40px",
              height: "38px",
              margin: "0 6px",
              padding: "5px",
            }}
            className="form-control"
            value={catColor}
            onChange={(event) => {
              setCatColor(event.target.value);
            }}
          />
        </div>
        <div className="label">Thème De Menu</div>
        <div className="display">
          <button
            className={
              (menuColor === "dark" ? "active-btn" : "light-btn-2") +
              " extrabtnOption"
            }
            onClick={() => {
              setMenuColor("dark");
            }}
          >
            Thème sombre
          </button>
          <button
            className={
              (menuColor === "light" ? "active-btn" : "light-btn-2") +
              " extrabtnOption"
            }
            onClick={() => {
              setMenuColor("light");
            }}
          >
            Thème clair
          </button>
        </div>
      </div>

      {/* <div className="mt-4">
        <div className="label">Classer Prix </div>
        <div className="display">
          <button
            className={
              (classer === "asc" ? "active-btn" : "light-btn-2") +
              " extrabtnOption"
            }
            onClick={() => {
              setclasser("asc");
            }}
          >
            Croissant
          </button>
          <button
            className={
              (classer === "desc" ? "active-btn" : "light-btn-2") +
              " extrabtnOption"
            }
            onClick={() => {
              setclasser("desc");
            }}
          >
            Décroissant
          </button>
        </div>
      </div> */}

      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <button
          className="light-btn addbtn"
          onClick={() => {
            editMenuNow();
          }}
          disabled={loading}
        >
          {!loading ? "Sauvegarder" : <Loader />}
        </button>
      </div>

      <div style={{ marginTop: "150px" }}>
        <button
          className="light-btn"
          onClick={() => {
            restoreCategorie();
          }}
          style={{ background: "#f52222", border: "2px solid #f52222" }}
          disabled={loading}
        >
          {!loadingRestore ? "Restaurer les catégories" : <Loader />}
        </button>
        <p style={{ textAlign: "justify", marginTop: "10px" }}>
          Ce bouton redémarrera une sauvegarde du menu dans les dernières 24
          heures
        </p>
      </div>
    </>
  );
};

export default EditSettingMenu;
