import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { deleteImage } from "../../actions/imageActions";
import { sweetBox } from "../../utils/alert";

export default function UserImageList() {
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "image",
      headerName: "Image",
      width: 320,
      renderCell: (params) => {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              style={{
                width: "200px",
                height: "240px",
              }}
              src={
                params.row.image != null
                  ? params.row.image
                  : "/images/avatar.png"
              }
              alt={params.row.id}
            />
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div>
              <button
                className="btn btn-primary "
                style={{ backgroundColor: "red " }}
                onClick={() => {
                  deleteImageNow(params.row);
                }}
              >
                Supprimer
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const imageListMy = useSelector((state) => state.imageListMy);
  const { loading, images } = imageListMy;

  const deleteImageNow = (image) => {
    sweetBox({
      icon: "warning",
      type: "warning",
      name: "supprimer",
      title: "Voulez-vous supprimer cette Image ?",
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteImage(image));
    });
  };

  return (
    <div className="container">
      <div className="mt-5 mb-5 label">Liste d'images </div>
      <div>
        <DataGrid
          rowHeight={250}
          autoHeight
          loading={loading}
          rows={images}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </div>
    </div>
  );
}
